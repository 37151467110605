import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { surahs } from './data/surahs';
import { reciters } from './data/reciters';
import { Volume2, VolumeX, Loader2, Music } from 'lucide-react';
import SurahItem from './components/SurahItem';
import './styles/QuranPlayer.css';
import Header from './components/Header';
import ReciterSelector from "./components/ReciterSelector";
import { toast } from 'react-toastify';
import SurahSearch from './components/SurahSearch';
import { CustomBottomSheet } from './components/CustomBottomSheet';
import { motion, AnimatePresence } from 'framer-motion';
import { PlayerContext } from './App';

export default function QuranAudioPlayer({ initialSurah, initialReciter, initialIsPlaying, onStateChange }) {
  const { playerState, setPlayerState } = useContext(PlayerContext);
  const [currentSurah, setCurrentSurah] = useState(initialSurah || null);
  const [isPlaying, setIsPlaying] = useState(initialIsPlaying || false);
  const [audio] = useState(new Audio());
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [currentAudio] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [isRepeatEnabled, setIsRepeatEnabled] = useState(false);
  const [loadingSurahId, setLoadingSurahId] = useState(null);
  const [selectedReciter, setSelectedReciter] = useState(reciters[null]);
  const [filteredSurahs, setFilteredSurahs] = useState(surahs);  
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);
  
  // تعريف المراجع أولاً
  const currentSurahRef = useRef(currentSurah);
  const playSurahRef = useRef(null);
  const updateMediaMetadataRef = useRef(null);
  const repeatRef = useRef(isRepeatEnabled);

  const normalizeText = (text) => {
    return text.normalize("NFD")
    .replace(/[\u064B-\u065F\u0670]/g, "") // إزالة التشكيل فقط
      .replace(/ٱ/g, "ا")
      .replace(/[أإآ]/g, "ا")
      .replace(/\s/g, ''); // إزالة جميع المسافات
  };
  
  const handleSearch = (searchTerm) => {
    const normalizedTerm = normalizeText(searchTerm.trim()); // إزالة التشكيل وتصحيح الألف الصغيرة
    const filtered = surahs.filter((surah) => {
      const normalizedName = normalizeText(surah.name); // إزالة التشكيل وتصحيح الألف الصغيرة
      return normalizedName.includes(normalizedTerm) || 
             surah.id.toString().includes(searchTerm);
    });
  
    setFilteredSurahs(filtered);
  };
  
  useEffect(() => {
    repeatRef.current = isRepeatEnabled;
  }, [isRepeatEnabled]);

  const stopAllAudio = useCallback(() => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.src = '';
      currentAudio.load();
      setCurrentTime(0);
      setProgress(0);
    }
  }, [currentAudio]);

  // تعريف دالة navigateSurah قبل استخدامها
  const navigateSurah = useCallback((direction) => {
    if (!currentSurah) return;
    const currentIndex = surahs.findIndex((surah) => surah.id === currentSurah.id);
    let newIndex;
    if (direction === 'next') {
      newIndex = (currentIndex + 1) % surahs.length;
    } else if (direction === 'prev') {
      newIndex = (currentIndex - 1 + surahs.length) % surahs.length;
    }
    const newSurah = surahs[newIndex];
    setCurrentTime(0);
    setProgress(0);
    if (playSurahRef.current) {
      playSurahRef.current(newSurah, false);
    }
  }, [currentSurah]);

  // تعريف togglePlayPause قبل استخدامه
  const togglePlayPause = useCallback(() => {
    console.log('togglePlayPause تم استدعاء', { isPlaying, audioState: audio.paused ? 'paused' : 'playing' });
    
    if (isPlaying) {
      // إيقاف التشغيل
      Promise.resolve()
        .then(() => {
          audio.pause();
          setIsPlaying(false);
          
          // إعادة تعيين حالة مشغل الوسائط عند الإيقاف
          if ('mediaSession' in navigator) {
            navigator.mediaSession.playbackState = 'paused';
          }
        })
        .catch(error => {
          console.error('خطأ في إيقاف الصوت:', error);
        });
    } else {
      // بدء التشغيل
      if (audio && audio.src && !audio.ended) {
        setLoadingSurahId(currentSurah?.id);
        Promise.resolve()
          .then(() => {
            return audio.play();
          })
          .then(() => {
            setIsPlaying(true);
            setLoadingSurahId(null);
            // تحديث معلومات مشغل الوسائط
            if (updateMediaMetadataRef.current) {
              updateMediaMetadataRef.current(currentSurah, selectedReciter);
            }
            
            // تحديث حالة التشغيل في مشغل الوسائط
            if ('mediaSession' in navigator) {
              navigator.mediaSession.playbackState = 'playing';
            }
          })
          .catch((error) => {
            console.error('Error resuming audio:', error);
            setLoadingSurahId(null);
          });
      } else if (currentSurah) {
        playSurahRef.current(currentSurah);
      }
    }
  }, [audio, isPlaying, currentSurah, selectedReciter]);

  // تحديث معلومات مشغل الوسائط في الخلفية
  const updateMediaSessionMetadata = useCallback((surah, reciter) => {
    if ('mediaSession' in navigator && surah) {
      // تحديث البيانات الوصفية
      navigator.mediaSession.metadata = new MediaMetadata({
        title: `سورة ${surah.name} - ${surah.aya} آية`,
        artist: reciter ? reciter.label : 'القرآن الكريم',
        album: 'القرآن الكريم',
        artwork: [
          { src: `${process.env.PUBLIC_URL}/logo192.png`, sizes: '96x96', type: 'image/png' },
          { src: `${process.env.PUBLIC_URL}/logo192.png`, sizes: '192x192', type: 'image/png' },
          { src: `${process.env.PUBLIC_URL}/logo512.png`, sizes: '512x512', type: 'image/png' },
        ]
      });
      
      // تحديث حالة التشغيل
      navigator.mediaSession.playbackState = isPlaying ? 'playing' : 'paused';
      
      // تحديث أزرار التحكم في مشغل الوسائط - تحسين معالجات الأحداث
      navigator.mediaSession.setActionHandler('play', () => {
        console.log('MediaSession: تم طلب التشغيل', { isPlaying, audioState: audio.paused ? 'paused' : 'playing' });
        
        // تحقق من حالة الصوت الفعلية وليس فقط متغير الحالة
        if (audio.paused) {
          if (audio.src) {
            setLoadingSurahId(currentSurahRef.current?.id);
            
            // استخدام وعد للتأكد من اكتمال العملية
            Promise.resolve()
              .then(() => {
                return audio.play();
              })
              .then(() => {
                setIsPlaying(true);
                setLoadingSurahId(null);
                navigator.mediaSession.playbackState = 'playing';
              })
              .catch(error => {
                console.error('خطأ في تشغيل الصوت:', error);
                setLoadingSurahId(null);
              });
          } else if (currentSurahRef.current && playSurahRef.current) {
            playSurahRef.current(currentSurahRef.current);
          }
        }
      });
      
      navigator.mediaSession.setActionHandler('pause', () => {
        console.log('MediaSession: تم طلب الإيقاف', { isPlaying, audioState: audio.paused ? 'paused' : 'playing' });
        
        // تحقق من حالة الصوت الفعلية وليس فقط متغير الحالة
        if (!audio.paused) {
          // استخدام وعد للتأكد من اكتمال العملية
          Promise.resolve()
            .then(() => {
              audio.pause();
              setIsPlaying(false);
              navigator.mediaSession.playbackState = 'paused';
            })
            .catch(error => {
              console.error('خطأ في إيقاف الصوت:', error);
            });
        }
      });
      
      // تعريف دالة للانتقال بين السور
      navigator.mediaSession.setActionHandler('previoustrack', () => {
        navigateSurah('prev');
      });
      
      navigator.mediaSession.setActionHandler('nexttrack', () => {
        navigateSurah('next');
      });
      
      navigator.mediaSession.setActionHandler('seekto', (details) => {
        if (audio && details.seekTime !== undefined) {
          audio.currentTime = details.seekTime;
          setCurrentTime(details.seekTime);
          setProgress((details.seekTime / duration) * 100);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, isPlaying, duration, navigateSurah]);

  // تحديث مرجع دالة updateMediaSessionMetadata
  useEffect(() => {
    updateMediaMetadataRef.current = updateMediaSessionMetadata;
  }, [updateMediaSessionMetadata]);

  const playSurah = useCallback(
    (surah, isManual = false) => {
      if (isWaiting) return;
  
      if (!selectedReciter) {
        toast.info("يجب اختيار قارئ قبل تشغيل السورة !");
        return;
      }
  
      const filename = surah.audioUrl.split('/').pop();
      const dynamicAudioUrl = `https://${selectedReciter.name}/${filename}`;
      
      // تحقق مما إذا كانت السورة والقارئ نفس ما هو قيد التشغيل
      const isSameSurahAndReciter =
        currentSurah?.id === surah.id &&
        audio.src === dynamicAudioUrl;
  
      if (isSameSurahAndReciter) {
        if (isPlaying) {
          Promise.resolve()
            .then(() => {
              audio.pause();
              setIsPlaying(false);
              
              // تحديث حالة التشغيل في مشغل الوسائط
              if ('mediaSession' in navigator) {
                navigator.mediaSession.playbackState = 'paused';
              }
            })
            .catch(error => {
              console.error('خطأ في إيقاف الصوت:', error);
            });
        } else {
          setLoadingSurahId(surah.id);
          Promise.resolve()
            .then(() => {
              return audio.play();
            })
            .then(() => {
              setIsPlaying(true);
              setLoadingSurahId(null);
              // تحديث معلومات مشغل الوسائط
              if (updateMediaMetadataRef.current) {
                updateMediaMetadataRef.current(surah, selectedReciter);
              }
              
              // تحديث حالة التشغيل في مشغل الوسائط
              if ('mediaSession' in navigator) {
                navigator.mediaSession.playbackState = 'playing';
              }
            })
            .catch((error) => {
              console.error('Error resuming audio:', error);
              setLoadingSurahId(null);
            });
        }
        return;
      }
  
      stopAllAudio();
      setLoadingSurahId(surah.id);
  
      // استبدال إنشاء newAudio جديد
      audio.src = dynamicAudioUrl;
      audio.load();
  
      // تحديد الوقت الذي سيتم البدء منه (من حالة يديوية أو صفر)
      audio.currentTime = isManual ? currentTime : 0;
  
      // إعداد التعامل مع البيانات الصوتية
      const handleLoadedMetadata = () => {
        setDuration(audio.duration);
        // إزالة المستمع بعد استخدامه مرة واحدة
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
      
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
  
      Promise.resolve()
        .then(() => {
          return audio.play();
        })
        .then(() => {
          setIsPlaying(true);
          setLoadingSurahId(null);
          // تحديث معلومات مشغل الوسائط
          if (updateMediaMetadataRef.current) {
            updateMediaMetadataRef.current(surah, selectedReciter);
          }
          
          // تحديث حالة التشغيل في مشغل الوسائط
          if ('mediaSession' in navigator) {
            navigator.mediaSession.playbackState = 'playing';
          }
        })
        .catch((error) => {
          console.error('Error playing audio:', error);
          setLoadingSurahId(null);
          // إزالة المستمع في حالة الخطأ
          audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        });
  
      setCurrentSurah(surah);
    },
    [stopAllAudio, currentTime, isWaiting, currentSurah, isPlaying, audio, selectedReciter, updateMediaMetadataRef]
  );

  // تحديث المراجع بعد تعريف الدوال
  useEffect(() => {
    currentSurahRef.current = currentSurah;
    playSurahRef.current = playSurah;
  }, [currentSurah, playSurah]);

  // تحديث حالة مشغل الوسائط عند تغيير الوقت
  useEffect(() => {
    if ('mediaSession' in navigator && currentSurah && duration > 0) {
      navigator.mediaSession.setPositionState({
        duration: duration,
        playbackRate: playbackSpeed,
        position: currentTime
      });
    }
  }, [currentTime, duration, currentSurah, playbackSpeed]);

  useEffect(() => {
    let animationFrameId;
    const updateProgress = () => {
      if (audio && duration > 0) {
        const safeCurrentTime = Math.min(audio.currentTime, duration);
        const progressPercentage = Math.min((safeCurrentTime / duration) * 100, 100);
        setCurrentTime(safeCurrentTime);
        setProgress(progressPercentage);
        if (!audio.paused && !audio.ended) {
          animationFrameId = requestAnimationFrame(updateProgress);
        }
      }
    };
    if (isPlaying) {
      animationFrameId = requestAnimationFrame(updateProgress);
    }
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [audio, isPlaying, duration]);

  const toggleMute = useCallback(() => {
    const newMuteState = !isMuted;
    setIsMuted(newMuteState);
    audio.muted = newMuteState;
  }, [audio, isMuted]);

  const handleSpeedChange = useCallback((speed) => {
    setPlaybackSpeed(speed);
    if (audio) {
      audio.playbackRate = speed;
      
      // تحديث سرعة التشغيل في مشغل الوسائط
      if ('mediaSession' in navigator && currentSurah) {
        navigator.mediaSession.setPositionState({
          duration: duration,
          playbackRate: speed,
          position: currentTime
        });
      }
    }
  }, [audio, currentSurah, currentTime, duration]);

  const toggleRepeat = useCallback(() => {
    setIsRepeatEnabled((prev) => !prev);
  }, []);

  // إضافة مستمعي أحداث للصوت
  useEffect(() => {
    if (audio) {
      // مستمع لحدث التشغيل
      const handlePlay = () => {
        console.log('حدث تشغيل الصوت');
        setIsPlaying(true);
        if ('mediaSession' in navigator) {
          navigator.mediaSession.playbackState = 'playing';
        }
      };
      
      // مستمع لحدث الإيقاف
      const handlePause = () => {
        console.log('حدث إيقاف الصوت');
        setIsPlaying(false);
        if ('mediaSession' in navigator) {
          navigator.mediaSession.playbackState = 'paused';
        }
      };
      
      // مستمع لحدث انتهاء التشغيل
      const handleEnded = () => {
        console.log('حدث انتهاء الصوت');
        setIsPlaying(false);
        if ('mediaSession' in navigator) {
          navigator.mediaSession.playbackState = 'none';
        }
        
        if (repeatRef.current) {
          audio.currentTime = 0;
          setProgress(0);
          audio.play().catch((error) => console.error('Error repeating audio:', error));
        } else if (currentSurah) {
          const currentIndex = surahs.findIndex((s) => s.id === currentSurah.id);
          setIsWaiting(true);
          setTimeout(() => {
            setIsWaiting(false);
            const nextIndex = (currentIndex + 1) % surahs.length;
            const nextSurah = surahs[nextIndex];
            playSurah(nextSurah);
          }, 3000);
        }
      };
      
      // مستمع لحدث الخطأ
      const handleError = (error) => {
        console.error('حدث خطأ في الصوت:', error);
        setIsPlaying(false);
        setLoadingSurahId(null);
        
        // محاولة إعادة تحميل الصوت في حالة الخطأ
        if (currentSurah && selectedReciter) {
          const filename = currentSurah.audioUrl.split('/').pop();
          const dynamicAudioUrl = `https://${selectedReciter.name}/${filename}`;
          
          // إعادة تعيين مصدر الصوت
          setTimeout(() => {
            audio.src = dynamicAudioUrl;
            audio.load();
            toast.error("حدث خطأ في تشغيل الصوت. يرجى المحاولة مرة أخرى.");
          }, 1000);
        }
      };
      
      // مستمع لحدث تعليق التشغيل
      const handleSuspend = () => {
        console.log('تم تعليق تحميل الصوت');
      };
      
      // مستمع لحدث استئناف التشغيل
      const handleResume = () => {
        console.log('تم استئناف تشغيل الصوت');
      };
      
      // مستمع لحدث تغيير الحالة
      const handleStateChange = () => {
        console.log('تغيرت حالة الصوت:', audio.readyState);
      };
      
      // إضافة المستمعين
      audio.addEventListener('play', handlePlay);
      audio.addEventListener('pause', handlePause);
      audio.addEventListener('ended', handleEnded);
      audio.addEventListener('error', handleError);
      audio.addEventListener('suspend', handleSuspend);
      audio.addEventListener('resume', handleResume);
      audio.addEventListener('readystatechange', handleStateChange);
      
      // إزالة المستمعين عند تفكيك المكون
      return () => {
        audio.removeEventListener('play', handlePlay);
        audio.removeEventListener('pause', handlePause);
        audio.removeEventListener('ended', handleEnded);
        audio.removeEventListener('error', handleError);
        audio.removeEventListener('suspend', handleSuspend);
        audio.removeEventListener('resume', handleResume);
        audio.removeEventListener('readystatechange', handleStateChange);
      };
    }
  }, [audio, currentSurah, playSurah, selectedReciter]);

  useEffect(() => {
    if (selectedReciter && currentSurahRef.current) {
      playSurahRef.current(currentSurahRef.current);
    }
  }, [selectedReciter]);

  const handleSeek = useCallback((e) => {
    if (audio) {
      const seekTime = (e.target.value / 100) * duration;
      audio.currentTime = seekTime;
      setCurrentTime(seekTime);
      setProgress((seekTime / duration) * 100);
    }
  }, [audio, duration]);

  const formatTime = useCallback((time) => {
    if (!time || isNaN(time)) return '0:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }, []);

  const toArabicNumerals = useCallback((num) => {
    const arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    return num.toString().split('').map(digit => arabicNumerals[digit]).join('');
  }, []);

  const togglePlayerVisibility = useCallback(() => {
    setIsPlayerVisible(!isPlayerVisible);
  }, [isPlayerVisible]);

  // منع تمرير الصفحة الخلفية عند فتح النافذة
  useEffect(() => {
    if (isPlayerVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isPlayerVisible]);

  // إضافة مستمع لأحداث دورة حياة التطبيق
  useEffect(() => {
    // التعامل مع حدث عندما يصبح التطبيق مخفيًا
    const handleVisibilityChange = () => {
      console.log('تغيرت حالة رؤية الصفحة:', document.visibilityState);
      
      // تحديث حالة التشغيل في MediaSession عند تغير حالة الرؤية
      if ('mediaSession' in navigator && currentSurah) {
        if (document.visibilityState === 'visible') {
          // عند العودة للتطبيق، تحديث حالة التشغيل
          navigator.mediaSession.playbackState = isPlaying ? 'playing' : 'paused';
          
          // تحديث معلومات مشغل الوسائط
          if (updateMediaMetadataRef.current) {
            updateMediaMetadataRef.current(currentSurah, selectedReciter);
          }
        }
      }
    };
    
    // إضافة مستمع لحدث تغير حالة الرؤية
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    // إزالة المستمع عند تفكيك المكون
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [currentSurah, isPlaying, selectedReciter]);

  // تحديث الحالة العامة عند تغيير حالة المشغل
  useEffect(() => {
    if (onStateChange) {
      onStateChange({
        isVisible: true,
        currentSurah,
        selectedReciter,
        isPlaying
      });
    }
  }, [currentSurah, selectedReciter, isPlaying, onStateChange]);

  // استخدام القيم الأولية عند التحميل
  useEffect(() => {
    if (initialSurah && initialReciter) {
      setCurrentSurah(initialSurah);
      setSelectedReciter(initialReciter);
      if (initialIsPlaying) {
        playSurahRef.current(initialSurah);
      }
    }
  }, [initialSurah, initialReciter, initialIsPlaying]);

  return (
    <div
      className={`min-h-screen px-4 sm:px-6 md:px-8 rtl:px-4 sm:rtl:px-6 md:rtl:px-8 font-inter 
        ${playerState.isDarkMode ? 
          'bg-gray-900 text-white' : 
          'bg-gradient-to-b from-emerald-50 to-teal-100'
        }`
      }
    >
      <Header isDarkMode={playerState.isDarkMode} />
      <ReciterSelector 
        reciters={reciters}
        selectedReciter={selectedReciter}
        onSelect={setSelectedReciter}
        isDarkMode={playerState.isDarkMode}
      />
      <SurahSearch surahs={surahs} onSearch={handleSearch} isDarkMode={playerState.isDarkMode} />
      <div className="scrollable rounded-lg max-h-[60vh] overflow-y-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4">
          {filteredSurahs.map((surah) => (
            <SurahItem
              key={surah.id}
              surah={surah}
              isPlaying={isPlaying && currentSurah?.id === surah.id}
              onPlay={() => {
                playSurah(surah);
                setIsPlayerVisible(true);
              }}
              isLoading={loadingSurahId === surah.id}
              isDarkMode={playerState.isDarkMode}
            />
          ))}
        </div>
      </div>

      {/* زر تشغيل/إيقاف المشغل */}
      <AnimatePresence>
        {currentSurah && !isPlayerVisible && (
          <motion.button 
            onClick={togglePlayerVisibility}
            className={`fixed bottom-6 right-6 z-50 control-btn flex items-center justify-center w-12 h-12 rounded-full text-white transition-colors shadow-lg ${
              isPlaying
                ? playerState.isDarkMode
                  ? 'bg-red-600 hover:bg-red-700 active-btn scale-[1.02] border-2 border-red-700'
                  : 'bg-red-500 hover:bg-red-600 active-btn scale-[1.02] border-2 border-red-600'
                : playerState.isDarkMode
                  ? 'bg-emerald-600 hover:bg-emerald-700'
                  : 'bg-emerald-500 hover:bg-emerald-600'
            }`}
            initial={{ y: 100, opacity: 0, scale: 0.3 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            exit={{ y: 20, opacity: 0, scale: 0.5 }}
            transition={{ 
              type: "spring",
              stiffness: 260,
              damping: 20,
              duration: 0.3
            }}
            aria-label={isPlaying ? 'إيقاف التشغيل' : 'تشغيل'}
          >
            {isPlaying ? (
              <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-6 h-6 text-white"
                initial={{ scale: 0, rotate: -180 }}
                animate={{ scale: 1, rotate: 0 }}
                exit={{ scale: 0, rotate: 180 }}
                transition={{ 
                  type: "spring",
                  stiffness: 200,
                  damping: 15,
                  duration: 0.8
                }}
              >
                <rect x="6" y="4" width="4" height="16" />
                <rect x="14" y="4" width="4" height="16" />
              </motion.svg>
            ) : (
              <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-6 h-6 text-white"
                initial={{ scale: 0, rotate: 180 }}
                animate={{ scale: 1, rotate: 0 }}
                exit={{ scale: 0, rotate: -180 }}
                transition={{ 
                  type: "spring",
                  stiffness: 200,
                  damping: 15,
                  duration: 0.8
                }}
              >
                <polygon points="5 3 19 12 5 21 5 3" />
              </motion.svg>
            )}
          </motion.button>
        )}
      </AnimatePresence>

      {/* استخدام مكون CustomBottomSheet الجديد */}
      {currentSurah && (
        <CustomBottomSheet 
          isOpen={isPlayerVisible} 
          onDismiss={togglePlayerVisibility}
          isDarkMode={playerState.isDarkMode}
        >
          <div className="player-content p-4">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-4 rtl:space-x-reverse">
                <button
                  onClick={toggleMute}
                  className="text-emerald-600 hover:text-emerald-800 transition duration-200"
                >
                  {isMuted ? (
                    <VolumeX className="w-7 h-7" />
                  ) : (
                    <Volume2 className="w-7 h-7" />
                  )}
                </button>
                <span className="text-emerald-900 dark:text-emerald-100 text-lg font-semibold">
                  سُورَةُ {currentSurah.name}
                </span>
              </div>
              <span className="text-sm bg-emerald-500 text-white px-2.5 py-0.5 rounded-full">
                {toArabicNumerals(currentSurah.aya)} آية
              </span>
            </div>
            <div className="flex items-center rtl:space-x-reverse">
              <span className="text-sm text-gray-500 dark:text-gray-300 w-14 text-right">
                {formatTime(currentTime)}
              </span>
              <input
                type="range"
                value={progress}
                onChange={handleSeek}
                max="100"
                className="w-full h-2 rounded-full appearance-none bg-emerald-200 dark:bg-emerald-800 accent-emerald-600 cursor-pointer"
                style={{ '--progress': `${progress}%` }}
              />
              <span className="text-sm text-gray-500 dark:text-gray-300 w-14 text-left">
                {formatTime(duration)}
              </span>
            </div>
            <div className="flex flex-col items-center mt-3">
              <div className="audio-controls flex ">
                <button
                  onClick={toggleRepeat}
                  className={`control-btn ${isRepeatEnabled ? 'active-btn' : ''}`}
                  title={isRepeatEnabled ? 'إيقاف التكرار' : 'تكرار السورة'}
                >
                  <i className="fas fa-repeat"></i>
                </button>
                <button
                  onClick={() => navigateSurah('prev')}
                  className="control-btn"
                  title="السورة السابقة"
                >
                  <i className="fas fa-step-backward"></i>
                </button>
                <button
                  onClick={togglePlayPause}
                  className={`
                    control-btn play-btn 
                    flex items-center justify-center 
                    w-12 h-12 rounded-full 
                    bg-emerald-500 
                    text-white 
                    transition-all 
                    duration-300 
                    ${(isPlaying || loadingSurahId === currentSurah?.id) ?
                      'active-btn border-2 border-emerald-700 scale-[1.02] shadow-lg' :
                      'scale-100 shadow-sm'
                    }
                    ${loadingSurahId === currentSurah?.id ?
                      '!bg-emerald-600 cursor-wait' :
                      'hover:bg-emerald-600'
                    }
                  `}
                  disabled={loadingSurahId === currentSurah?.id}
                  title={isPlaying ? 'إيقاف' : 'تشغيل'}
                >
                  {loadingSurahId === currentSurah?.id ? (
                    <Loader2 className="w-6 h-6 animate-spin" />
                  ) : isPlaying ? (
                    <i className="fas fa-pause text-lg"></i>
                  ) : (
                    <i className="fas fa-play text-lg"></i>
                  )}
                </button>
                <button
                  onClick={() => navigateSurah('next')}
                  className="control-btn"
                  title="السورة التالية"
                >
                  <i className="fas fa-step-forward"></i>
                </button>
                <button
                  onClick={() =>
                    handleSpeedChange(playbackSpeed === 2 ? 0.5 : playbackSpeed + 0.5)
                  }
                  className="control-btn speed-btn"
                  title="تغيير سرعة التشغيل"
                >
                  {playbackSpeed}x
                </button>
              </div>
            </div>
          </div>
        </CustomBottomSheet>
      )}
    </div>
  );
}