// قائمة المؤذنين المتاحين في التطبيق
const muezzins = [
  {
    id: 'default',
    name: 'الافتراضي',
    file: 'adhan.mp3',
    path: '/assets/adhan.mp3',
    description: 'صوت الأذان الافتراضي للتطبيق'
  },
  {
    id: 'sarihi',
    name: 'عبدالمجيد السريحي',
    file: 'adhan-sarihi.mp3',
    path: '/assets/adhan-sarihi.mp3',
    description: 'أذان بصوت القارئ عبدالمجيد السريحي'
  },
  {
    id: 'makkah',
    name: 'الحرم المكي',
    file: 'adhan-makkah.mp3',
    path: '/assets/adhan-makkah.mp3',
    description: 'أذان الحرم المكي الشريف'
  },
  {
    id: 'madinah',
    name: 'الحرم النبوي',
    file: 'adhan-madinah.mp3',
    path: '/assets/adhan-madinah.mp3',
    description: 'أذان المسجد النبوي الشريف'
  }
];

export default muezzins; 