// Header.js
import React from 'react';

const Header = () => {
    return (
        <header
            className="relative text-center py-5  from-emerald-700 to-emerald-500 text-white rounded-b-3xl overflow-hidden pt-20"
            >
            {/* زخرفة إسلامية في الخلفية */}
            <div className="absolute inset-0 opacity-20 bg-[url('https://www.transparenttextures.com/patterns/arabesque.png')]" />
            {/* آية قرآنية */}
            <p className="text-lg max-w-3xl mx-auto font-medium leading-relaxed drop-shadow">
               
            </p>
            {/* فاصل مزخرف */}
           

        </header>
    );
};

export default Header;