export const surahs = [
  { id: 1, name: "ٱلْفَاتِحَةُ", aya: 7, audioUrl: "/001.mp3" },
  { id: 2, name: "ٱلْبَقَرَةُ", aya: 286, audioUrl: "/002.mp3" },
  { id: 3, name: "آلِ عِمْرَانَ", aya: 200, audioUrl: "/003.mp3" },
  { id: 4, name: "ٱلنِّسَاءُ", aya: 176, audioUrl: "/004.mp3" },
  { id: 5, name: "ٱلْمَائِدَةُ", aya: 120, audioUrl: "/005.mp3" },
  { id: 6, name: "ٱلْأَنْعَامُ", aya: 165, audioUrl: "/006.mp3" },
  { id: 7, name: "ٱلْأَعْرَافُ", aya: 206, audioUrl: "/007.mp3" },
  { id: 8, name: "ٱلْأَنْفَالُ", aya: 75, audioUrl: "/008.mp3" },
  { id: 9, name: "ٱلتَّوْبَةُ", aya: 129, audioUrl: "/009.mp3" },
  { id: 10, name: "يُونُسُ", aya: 109, audioUrl: "/010.mp3" },
  { id: 11, name: "هُودٌ", aya: 123, audioUrl: "/011.mp3" },
  { id: 12, name: "يُوسُفُ", aya: 111, audioUrl: "/012.mp3" },
  { id: 13, name: "ٱلرَّعْدُ", aya: 43, audioUrl: "/013.mp3" },
  { id: 14, name: "إِبْرَٰهِيمُ", aya: 52, audioUrl: "/014.mp3" },
  { id: 15, name: "ٱلْحِجْرُ", aya: 99, audioUrl: "/015.mp3" },
  { id: 16, name: "ٱلنَّحْلُ", aya: 128, audioUrl: "/016.mp3" },
  { id: 17, name: "ٱلْإِسْرَاءُ", aya: 111, audioUrl: "/017.mp3" },
  { id: 18, name: "ٱلْكَهْفُ", aya: 110, audioUrl: "/018.mp3" },
  { id: 19, name: "مَرْيَمُ", aya: 98, audioUrl: "/019.mp3" },
  { id: 20, name: "طَهَ", aya: 135, audioUrl: "/020.mp3" },
  { id: 21, name: "ٱلْأَنْبِيَاءُ", aya: 112, audioUrl: "/021.mp3" },
  { id: 22, name: "ٱلْحَجُّ", aya: 78, audioUrl: "/022.mp3" },
  { id: 23, name: "ٱلْمُؤْمِنُونَ", aya: 118, audioUrl: "/023.mp3" },
  { id: 24, name: "ٱلنُّورُ", aya: 64, audioUrl: "/024.mp3" },
  { id: 25, name: "ٱلْفُرْقَانُ", aya: 77, audioUrl: "/025.mp3" },
  { id: 26, name: "ٱلشُّعَرَاءُ", aya: 227, audioUrl: "/026.mp3" },
  { id: 27, name: "ٱلنَّمْلُ", aya: 93, audioUrl: "/027.mp3" },
  { id: 28, name: "ٱلْقَصَصُ", aya: 88, audioUrl: "/028.mp3" },
  { id: 29, name: "ٱلْعَنْكَبُوتُ", aya: 69, audioUrl: "/029.mp3" },
  { id: 30, name: "ٱلرُّومُ", aya: 60, audioUrl: "/030.mp3" },
  { id: 31, name: "لُقْمَانُ", aya: 34, audioUrl: "/031.mp3" },
  { id: 32, name: "ٱلسَّجْدَةُ", aya: 30, audioUrl: "/032.mp3" },
  { id: 33, name: "ٱلْأَحْزَابُ", aya: 73, audioUrl: "/033.mp3" },
  { id: 34, name: "سَبَأٌ", aya: 54, audioUrl: "/034.mp3" },
  { id: 35, name: "فَاطِرٌ", aya: 45, audioUrl: "/035.mp3" },
  { id: 36, name: "يٓسٓ", aya: 83, audioUrl: "/036.mp3" },
  { id: 37, name: "ٱلصَّافَّاتُ", aya: 182, audioUrl: "/037.mp3" },
  { id: 38, name: "صٓ", aya: 88, audioUrl: "/038.mp3" },
  { id: 39, name: "ٱلزُّمَرُ", aya: 75, audioUrl: "/039.mp3" },
  { id: 40, name: "غَافِرٌ", aya: 85, audioUrl: "/040.mp3" },
  { id: 41, name: "فُصِّلَتْ", aya: 54, audioUrl: "/041.mp3" },
  { id: 42, name: "ٱلشُّورَى", aya: 53, audioUrl: "/042.mp3" },
  { id: 43, name: "ٱلزُّخْرُفُ", aya: 89, audioUrl: "/043.mp3" },
  { id: 44, name: "ٱلدُّخَانُ", aya: 59, audioUrl: "/044.mp3" },
  { id: 45, name: "ٱلْجَاثِيَةُ", aya: 37, audioUrl: "/045.mp3" },
  { id: 46, name: "ٱلْأَحْقَافُ", aya: 35, audioUrl: "/046.mp3" },
  { id: 47, name: "مُحَمَّدٌ", aya: 38, audioUrl: "/047.mp3" },
  { id: 48, name: "ٱلْفَتْحُ", aya: 29, audioUrl: "/048.mp3" },
  { id: 49, name: "ٱلْحُجُرَاتُ", aya: 18, audioUrl: "/049.mp3" },
  { id: 50, name: "قٓ", aya: 45, audioUrl: "/050.mp3" },
  { id: 51, name: "ٱلذَّارِيَاتُ", aya: 60, audioUrl: "/051.mp3" },
  { id: 52, name: "ٱلطُّورُ", aya: 49, audioUrl: "/052.mp3" },
  { id: 53, name: "ٱلنَّجْمُ", aya: 62, audioUrl: "/053.mp3" },
  { id: 54, name: "ٱلْقَمَرُ", aya: 55, audioUrl: "/054.mp3" },
  { id: 55, name: "ٱلرَّحْمَٰنُ", aya: 78, audioUrl: "/055.mp3" },
  { id: 56, name: "ٱلْوَاقِعَةُ", aya: 96, audioUrl: "/056.mp3" },
  { id: 57, name: "ٱلْحَدِيدُ", aya: 29, audioUrl: "/057.mp3" },
  { id: 58, name: "ٱلْمُجَادلَةُ", aya: 22, audioUrl: "/058.mp3" },
  { id: 59, name: "ٱلْحَشْرُ", aya: 24, audioUrl: "/059.mp3" },
  { id: 60, name: "ٱلْمُمْتَحَنَةُ", aya: 13, audioUrl: "/060.mp3" },
  { id: 61, name: "ٱلصَّفُّ", aya: 14, audioUrl: "/061.mp3" },
  { id: 62, name: "ٱلْجُمُعَةُ", aya: 11, audioUrl: "/062.mp3" },
  { id: 63, name: "ٱلْمُنَافِقُونَ", aya: 11, audioUrl: "/063.mp3" },
  { id: 64, name: "ٱلتَّغَابُنُ", aya: 18, audioUrl: "/064.mp3" },
  { id: 65, name: "ٱلطَّلَاقُ", aya: 12, audioUrl: "/065.mp3" },
  { id: 66, name: "ٱلتَّحْرِيمُ", aya: 12, audioUrl: "/066.mp3" },
  { id: 67, name: "ٱلْمُلْكُ", aya: 30, audioUrl: "/067.mp3" },
  { id: 68, name: "ٱلْقَلَمُ", aya: 52, audioUrl: "/068.mp3" },
  { id: 69, name: "ٱلْحَاقَّةُ", aya: 52, audioUrl: "/069.mp3" },
  { id: 70, name: "ٱلْمَعَارِجُ", aya: 44, audioUrl: "/070.mp3" },
  { id: 71, name: "نُوحٌ", aya: 28, audioUrl: "/071.mp3" },
  { id: 72, name: "ٱلْجِنُّ", aya: 28, audioUrl: "/072.mp3" },
  { id: 73, name: "ٱلْمُزَّمِّلُ", aya: 20, audioUrl: "/073.mp3" },
  { id: 74, name: "ٱلْمُدَّثِّرُ", aya: 56, audioUrl: "/074.mp3" },
  { id: 75, name: "ٱلْقِيَامَةُ", aya: 40, audioUrl: "/075.mp3" },
  { id: 76, name: "ٱلْإِنسَانُ", aya: 31, audioUrl: "/076.mp3" },
  { id: 77, name: "ٱلْمُرْسَلَاتُ", aya: 50, audioUrl: "/077.mp3" },
  { id: 78, name: "ٱلنَّبَأُ", aya: 40, audioUrl: "/078.mp3" },
  { id: 79, name: "ٱلنَّازِعَاتُ", aya: 46, audioUrl: "/079.mp3" },
  { id: 80, name: "عَبَسَ", aya: 42, audioUrl: "/080.mp3" },
  { id: 81, name: "ٱلتَّكْوِيرُ", aya: 29, audioUrl: "/081.mp3" },
  { id: 82, name: "ٱلْإِنفِطَارُ", aya: 19, audioUrl: "/082.mp3" },
  { id: 83, name: "ٱلْمُطَفِّفِينَ", aya: 36, audioUrl: "/083.mp3" },
  { id: 84, name: "ٱلْإِنشِقَاقُ", aya: 25, audioUrl: "/084.mp3" },
  { id: 85, name: "ٱلْبُرُوجُ", aya: 22, audioUrl: "/085.mp3" },
  { id: 86, name: "ٱلطَّارِقُ", aya: 17, audioUrl: "/086.mp3" },
  { id: 87, name: "ٱلْأَعْلَىٰ", aya: 19, audioUrl: "/087.mp3" },
  { id: 88, name: "ٱلْغَاشِيَةُ", aya: 26, audioUrl: "/088.mp3" },
  { id: 89, name: "ٱلْفَجْرُ", aya: 30, audioUrl: "/089.mp3" },
  { id: 90, name: "ٱلْبَلَدُ", aya: 20, audioUrl: "/090.mp3" },
  { id: 91, name: "ٱلشَّمْسُ", aya: 15, audioUrl: "/091.mp3" },
  { id: 92, name: "ٱللَّيْلُ", aya: 21, audioUrl: "/092.mp3" },
  { id: 93, name: "ٱلضُّحَىٰ", aya: 11, audioUrl: "/093.mp3" },
  { id: 94, name: "ٱلشَّرْحُ", aya: 8, audioUrl: "/094.mp3" },
  { id: 95, name: "ٱلتِّينُ", aya: 8, audioUrl: "/095.mp3" },
  { id: 96, name: "ٱلْعَلَقُ", aya: 19, audioUrl: "/096.mp3" },
  { id: 97, name: "ٱلْقَدْرُ", aya: 5, audioUrl: "/097.mp3" },
  { id: 98, name: "ٱلْبَيِّنَةُ", aya: 8, audioUrl: "/098.mp3" },
  { id: 99, name: "ٱلزَّلْزَلَةُ", aya: 8, audioUrl: "/099.mp3" },
  { id: 100, name: "ٱلْعَادِيَاتُ", aya: 11, audioUrl: "/100.mp3" },
  { id: 101, name: "ٱلْقَارِعَةُ", aya: 11, audioUrl: "/101.mp3" },
  { id: 102, name: "ٱلتَّكَاثُرُ", aya: 8, audioUrl: "/102.mp3" },
  { id: 103, name: "ٱلْعَصْرُ", aya: 3, audioUrl: "/103.mp3" },
  { id: 104, name: "ٱلْهُمَزَةُ", aya: 9, audioUrl: "/104.mp3" },
  { id: 105, name: "ٱلْفِيلُ", aya: 5, audioUrl: "/105.mp3" },
  { id: 106, name: "قُرَيْشٌ", aya: 4, audioUrl: "/106.mp3" },
  { id: 107, name: "ٱلْمَاعُونُ", aya: 7, audioUrl: "/107.mp3" },
  { id: 108, name: "ٱلْكَوْثَرُ", aya: 3, audioUrl: "/108.mp3" },
  { id: 109, name: "ٱلْكَافِرُونَ", aya: 6, audioUrl: "/109.mp3" },
  { id: 110, name: "ٱلنَّصْرُ", aya: 3, audioUrl: "/110.mp3" },
  { id: 111, name: "ٱلْمَسَدُ", aya: 5, audioUrl: "/111.mp3" },
  { id: 112, name: "ٱلْإِخْلَاصُ", aya: 4, audioUrl: "/112.mp3" },
  { id: 113, name: "ٱلْفَلَقُ", aya: 5, audioUrl: "/113.mp3" },
  { id: 114, name: "ٱلنَّاسُ", aya: 6, audioUrl: "/114.mp3" },
];
