import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import muezzins from '../data/muezzins';
import { Volume2, VolumeX, Check } from 'lucide-react';

const Muezzins = () => {
  const [selectedMuezzin, setSelectedMuezzin] = useState(
    localStorage.getItem('selectedMuezzin') || 'default'
  );
  const [adhanEnabled, setAdhanEnabled] = useState(
    localStorage.getItem('adhanEnabled') === 'true'
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const audioRef = useRef(null);
  const [audioVolume, setAudioVolume] = useState({});

  // إعلام شريط التنقل أن هذه الصفحة تستخدم زر الرجوع
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.isMuezzinsPage = true;
      // إرسال حدث لإعلام شريط التنقل بتغيير حالة صفحة المؤذنين
      window.dispatchEvent(new Event('muezzinsPageChanged'));
    }
    
    return () => {
      if (typeof window !== 'undefined') {
        window.isMuezzinsPage = false;
        // إرسال حدث لإعلام شريط التنقل بتغيير حالة صفحة المؤذنين
        window.dispatchEvent(new Event('muezzinsPageChanged'));
      }
    };
  }, []);

  // حفظ المؤذن المختار في التخزين المحلي
  useEffect(() => {
    localStorage.setItem('selectedMuezzin', selectedMuezzin);
  }, [selectedMuezzin]);

  // حفظ حالة تفعيل الأذان في التخزين المحلي
  useEffect(() => {
    localStorage.setItem('adhanEnabled', adhanEnabled);
  }, [adhanEnabled]);

  // إيقاف الصوت عند مغادرة الصفحة
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  // تشغيل صوت الأذان للمؤذن المحدد
  const playAdhan = (muezzinId) => {
    // إيقاف الصوت الحالي إذا كان يعمل
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }

    const muezzin = muezzins.find(m => m.id === muezzinId);
    if (!muezzin) return;

    const audio = new Audio(muezzin.path);
    audioRef.current = audio;
    setCurrentAudio(muezzinId);

    // إعداد مراقبة مستوى الصوت
    setAudioVolume(prev => ({ ...prev, [muezzinId]: [] }));
    
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaElementSource(audio);
    
    source.connect(analyser);
    analyser.connect(audioContext.destination);
    
    analyser.fftSize = 32;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    
    const updateVolume = () => {
      if (!audioRef.current || currentAudio !== muezzinId) return;
      
      analyser.getByteFrequencyData(dataArray);
      const average = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
      const normalizedVolume = average / 255;
      
      setAudioVolume(prev => ({
        ...prev,
        [muezzinId]: [...(prev[muezzinId] || []).slice(-4), normalizedVolume]
      }));
      
      if (isPlaying && currentAudio === muezzinId) {
        requestAnimationFrame(updateVolume);
      }
    };

    audio.addEventListener('play', () => {
      setIsPlaying(true);
      requestAnimationFrame(updateVolume);
    });
    
    audio.addEventListener('pause', () => {
      setIsPlaying(false);
      setAudioVolume(prev => ({ ...prev, [muezzinId]: [] }));
    });
    
    audio.addEventListener('ended', () => {
      setIsPlaying(false);
      setCurrentAudio(null);
      setAudioVolume(prev => ({ ...prev, [muezzinId]: [] }));
    });

    audio.play().catch(error => {
      console.error('فشل تشغيل الأذان:', error);
      setIsPlaying(false);
      setCurrentAudio(null);
    });
  };

  // إيقاف تشغيل الأذان
  const stopAdhan = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
      setIsPlaying(false);
      setCurrentAudio(null);
    }
  };

  // اختيار المؤذن
  const handleSelectMuezzin = (muezzinId) => {
    if (selectedMuezzin === muezzinId) return; // لا تفعل شيئًا إذا كان المؤذن مختارًا بالفعل
    
    setSelectedMuezzin(muezzinId);
    // حفظ المؤذن المختار في localStorage
    localStorage.setItem('selectedMuezzin', muezzinId);
    
    // تفعيل الأذان تلقائيًا إذا لم يكن مفعلاً
    if (!adhanEnabled) {
      setAdhanEnabled(true);
      localStorage.setItem('adhanEnabled', 'true');
    }
    
    // إيقاف الصوت الحالي إذا كان يعمل
    stopAdhan();
    
    // إظهار رسالة تأكيد للمستخدم
    const muezzin = muezzins.find(m => m.id === muezzinId);
    if (muezzin) {
      showToast(`تم اختيار ${muezzin.name} كمؤذن افتراضي`);
    }
  };

  // تغيير حالة تفعيل الأذان
  const handleAdhanToggle = () => {
    const newState = !adhanEnabled;
    setAdhanEnabled(newState);
    
    // حفظ الحالة في localStorage
    localStorage.setItem('adhanEnabled', newState);
    
    // إظهار رسالة تأكيد للمستخدم
    if (newState) {
      showToast('تم تفعيل الأذان بنجاح');
    } else {
      showToast('تم إيقاف تشغيل الأذان');
    }
  };

  // عرض رسالة تأكيد
  const showToast = (message) => {
    // إنشاء عنصر التنبيه
    const toast = document.createElement('div');
    toast.className = 'fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-emerald-600 text-white px-6 py-3 rounded-lg shadow-lg z-50 flex items-center';
    toast.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
      <span>${message}</span>
    `;
    
    // إضافة العنصر إلى الصفحة
    document.body.appendChild(toast);
    
    // إزالة العنصر بعد 3 ثوان
    setTimeout(() => {
      toast.style.opacity = '0';
      toast.style.transition = 'opacity 0.5s ease';
      setTimeout(() => {
        document.body.removeChild(toast);
      }, 500);
    }, 3000);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <main className="flex-1 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8 py-6 pt-20">
        <div className="bg-white shadow rounded-lg p-6 mb-6">
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-6">
            <div>
              <h2 className="text-lg font-medium text-gray-900">إعدادات الأذان</h2>
              <p className="text-gray-600">اختر المؤذن المفضل لديك واستمع إلى صوته</p>
            </div>
            
            <div className="flex items-center">
              <div className="flex items-center gap-3">
                <span className="text-gray-700">تفعيل الأذان</span>
                <div className="relative inline-block">
                  <motion.div
                    className={`w-14 h-7 rounded-full transition-colors ${
                      adhanEnabled ? 'bg-emerald-500' : 'bg-gray-300'
                    }`}
                    animate={{ backgroundColor: adhanEnabled ? '#10b981' : '#d1d5db' }}
                    transition={{ duration: 0.2 }}
                    onClick={handleAdhanToggle}
                    style={{ cursor: 'pointer' }}
                  >
                    <motion.div
                      className="absolute top-1 left-1 bg-white w-5 h-5 rounded-full shadow-md"
                      animate={{ 
                        x: adhanEnabled ? 28 : 0,
                      }}
                      transition={{ type: "spring", stiffness: 500, damping: 30 }}
                    />
                  </motion.div>
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={adhanEnabled}
                    onChange={handleAdhanToggle}
                  />
                </div>
              </div>
            </div>
          </div>
          
          {/* إضافة AnimatePresence لتحريك ظهور واختفاء قائمة المؤذنين */}
          <AnimatePresence>
            {adhanEnabled ? (
              <motion.div 
                className="grid gap-4"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                {muezzins.map((muezzin) => (
                  <motion.div
                    key={muezzin.id}
                    className={`p-4 rounded-lg border transition-all cursor-pointer ${
                      selectedMuezzin === muezzin.id 
                        ? 'bg-emerald-50 border-emerald-200' 
                        : 'bg-white border-gray-200 hover:bg-gray-50'
                    }`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ 
                      opacity: 1, 
                      y: 0,
                      scale: selectedMuezzin === muezzin.id ? 1.01 : 1
                    }}
                    transition={{ 
                      duration: 0.2,
                      delay: 0.1 * muezzins.indexOf(muezzin)
                    }}
                    onClick={() => handleSelectMuezzin(muezzin.id)}
                    whileTap={{ scale: 0.98 }}
                    whileHover={{ scale: 1.01 }}
                    style={{
                      boxShadow: selectedMuezzin === muezzin.id 
                        ? '0 4px 12px rgba(16, 185, 129, 0.15)'
                        : 'none'
                    }}
                  >
                    <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
                      <div className="flex-1">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-3">
                            <input
                              type="radio"
                              id={`muezzin-${muezzin.id}`}
                              name="muezzin"
                              checked={selectedMuezzin === muezzin.id}
                              onChange={() => handleSelectMuezzin(muezzin.id)}
                              className="w-5 h-5 text-emerald-500 focus:ring-0 focus:ring-offset-0"
                              onClick={(e) => e.stopPropagation()}
                            />
                            <label 
                              htmlFor={`muezzin-${muezzin.id}`} 
                              className="text-lg font-medium text-emerald-800 cursor-pointer select-none flex items-center gap-2"
                            >
                              {muezzin.name}
                              {selectedMuezzin === muezzin.id && (
                                <motion.span
                                  initial={{ scale: 0, opacity: 0 }}
                                  animate={{ scale: 1, opacity: 1 }}
                                  className="text-emerald-500"
                                >
                                  <Check size={18} />
                                </motion.span>
                              )}
                            </label>
                          </div>
                          
                          <motion.button
                            onClick={(e) => {
                              e.stopPropagation();
                              if (currentAudio === muezzin.id && isPlaying) {
                                stopAdhan();
                              } else {
                                playAdhan(muezzin.id);
                              }
                            }}
                            className={`px-4 py-2 rounded-md text-white flex items-center gap-2 transition-colors focus:outline-none ${
                              currentAudio === muezzin.id && isPlaying
                                ? 'bg-red-500 hover:bg-red-600'
                                : 'bg-emerald-500 hover:bg-emerald-600'
                            }`}
                            whileTap={{ scale: 0.95 }}
                            whileHover={{ scale: 1.05 }}
                            animate={{
                              scale: currentAudio === muezzin.id && isPlaying ? [1, 1.05, 1] : 1
                            }}
                            transition={{
                              scale: {
                                repeat: currentAudio === muezzin.id && isPlaying ? Infinity : 0,
                                repeatType: "reverse",
                                duration: 1.5
                              }
                            }}
                          >
                            {currentAudio === muezzin.id && isPlaying ? (
                              <>
                                <motion.div
                                  animate={{ 
                                    rotate: [0, 10, -10, 0],
                                  }}
                                  transition={{ 
                                    repeat: Infinity, 
                                    duration: 1.5,
                                    ease: "easeInOut"
                                  }}
                                >
                                  <VolumeX size={18} />
                                </motion.div>
                                إيقاف
                              </>
                            ) : (
                              <>
                                <Volume2 size={18} />
                                استماع
                              </>
                            )}
                          </motion.button>
                        </div>
                        <p className="text-gray-600 mt-1 mr-8">{muezzin.description}</p>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            ) : (
              <motion.div
                className="p-6 text-center"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3 }}
              >
                <div className="flex flex-col items-center justify-center p-6 bg-gray-50 rounded-lg border border-gray-200">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clipRule="evenodd" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
                  </svg>
                  <h3 className="text-lg font-medium text-gray-900 mb-2">الأذان معطل حالياً</h3>
                  <p className="text-gray-600 mb-4">قم بتفعيل الأذان للاستماع واختيار المؤذن المفضل لديك</p>
                  <button
                    onClick={handleAdhanToggle}
                    className="px-4 py-2 bg-emerald-500 text-white rounded-md hover:bg-emerald-600 transition-colors focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                  >
                    تفعيل الأذان
                  </button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        
        <AnimatePresence>
          {adhanEnabled && (
            <motion.div 
              className="bg-emerald-50 border border-emerald-200 rounded-lg p-6 shadow-md"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-xl font-medium text-emerald-800 mb-4 flex items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                معلومات وتعليمات
              </h2>
              <ul className="space-y-3 text-emerald-700 list-disc pr-5">
                <motion.li
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.1 }}
                >
                  سيتم تشغيل الأذان بصوت المؤذن المختار عند دخول وقت الصلاة.
                </motion.li>
                <motion.li
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  يمكنك الاستماع إلى صوت المؤذن قبل اختياره بالضغط على زر "استماع".
                </motion.li>
                <motion.li
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  سيتم تشغيل الأذان تلقائياً عند دخول وقت الصلاة إذا كان خيار "تفعيل الأذان" مفعلاً.
                </motion.li>
                <motion.li
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  يمكنك تغيير المؤذن في أي وقت من خلال هذه الصفحة.
                </motion.li>
                <motion.li
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  سيتم حفظ إعداداتك تلقائياً عند تغييرها.
                </motion.li>
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </main>
    </div>
  );
};

export default Muezzins;