import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated, config } from 'react-spring';
import '../styles/bottomSheet.css';

const CustomBottomSheet = ({ children, isOpen, onDismiss }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const contentRef = useRef(null);
  const overlayRef = useRef(null);
  const startY = useRef(0);
  const currentY = useRef(0);
  const isDragging = useRef(false);

  // تأثيرات الحركة للنافذة المنبثقة - تحسين الإعدادات
  const slideAnimation = useSpring({
    from: { 
      transform: 'translate3d(0, 100%, 0)', 
      opacity: 0 
    },
    to: {
      transform: isOpen 
        ? `translate3d(0, ${position.y}px, 0)` 
        : 'translate3d(0, 100%, 0)',
      opacity: isOpen ? 1 : 0
    },
    config: { 
      tension: 350, 
      friction: 26,
      ...config.stiff
    },
    onRest: () => {
      if (isFirstRender && isOpen) {
        setIsFirstRender(false);
      }
      setIsAnimating(false);
    },
    onStart: () => {
      setIsAnimating(true);
    },
    immediate: false
  });

  // تعيين الموضع الأولي عند فتح النافذة
  useEffect(() => {
    if (isOpen) {
      setPosition({ x: 0, y: 0 });
    }
  }, [isOpen]);

  // منطق السحب المبسط - يدعم اللمس والماوس
  useEffect(() => {
    // التعامل مع بدء اللمس
    const handleTouchStart = (e) => {
      // تجاهل الأحداث إذا كان العنصر المستهدف زر أو رابط أو عنصر تفاعلي آخر
      if (e.target.closest('button, a, .control-btn, .play-btn, input[type="range"]')) {
        return;
      }
      
      startY.current = e.touches[0].clientY;
      currentY.current = startY.current;
      isDragging.current = true;
    };

    // التعامل مع حركة اللمس
    const handleTouchMove = (e) => {
      if (!isDragging.current) return;
      
      currentY.current = e.touches[0].clientY;
      const deltaY = currentY.current - startY.current;
      
      // السماح بالسحب للأسفل فقط
      if (deltaY > 0) {
        setPosition({ x: 0, y: deltaY });
        e.preventDefault();
      }
    };

    // التعامل مع نهاية اللمس
    const handleTouchEnd = () => {
      if (!isDragging.current) return;
      
      isDragging.current = false;
      const deltaY = currentY.current - startY.current;
      
      // إغلاق النافذة إذا تم سحبها لمسافة كافية
      if (deltaY > 80) {
        onDismiss();
      } else {
        // إرجاع النافذة إلى موضعها الأصلي
        setPosition({ x: 0, y: 0 });
      }
    };

    // دعم الماوس - بدء السحب
    const handleMouseDown = (e) => {
      // تجاهل الأحداث إذا كان العنصر المستهدف زر أو رابط أو عنصر تفاعلي آخر
      if (e.target.closest('button, a, .control-btn, .play-btn, input[type="range"]')) {
        return;
      }
      
      startY.current = e.clientY;
      currentY.current = startY.current;
      isDragging.current = true;
      
      // تغيير مؤشر الماوس
      document.body.style.cursor = 'grabbing';
    };

    // دعم الماوس - حركة السحب
    const handleMouseMove = (e) => {
      if (!isDragging.current) return;
      
      currentY.current = e.clientY;
      const deltaY = currentY.current - startY.current;
      
      // السماح بالسحب للأسفل فقط
      if (deltaY > 0) {
        setPosition({ x: 0, y: deltaY });
        e.preventDefault();
      }
    };

    // دعم الماوس - نهاية السحب
    const handleMouseUp = () => {
      if (!isDragging.current) return;
      
      isDragging.current = false;
      const deltaY = currentY.current - startY.current;
      
      // إعادة مؤشر الماوس إلى الوضع الطبيعي
      document.body.style.cursor = '';
      
      // إغلاق النافذة إذا تم سحبها لمسافة كافية
      if (deltaY > 80) {
        onDismiss();
      } else {
        // إرجاع النافذة إلى موضعها الأصلي
        setPosition({ x: 0, y: 0 });
      }
    };

    // إضافة مستمعات الأحداث
    const content = contentRef.current;
    if (content) {
      // أحداث اللمس
      content.addEventListener('touchstart', handleTouchStart, { passive: true });
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('touchend', handleTouchEnd);
      
      // أحداث الماوس
      content.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    // إزالة مستمعات الأحداث عند تفكيك المكون
    return () => {
      if (content) {
        // إزالة أحداث اللمس
        content.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
        
        // إزالة أحداث الماوس
        content.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        
        // إعادة مؤشر الماوس إلى الوضع الطبيعي
        document.body.style.cursor = '';
      }
    };
  }, [onDismiss]);

  // إعادة تعيين حالة العرض الأول عند إغلاق النافذة
  useEffect(() => {
    if (!isOpen && !isAnimating) {
      // تأخير قصير لضمان اكتمال الرسوم المتحركة قبل إعادة تعيين الحالة
      const timer = setTimeout(() => {
        setIsFirstRender(true);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen, isAnimating]);

  // إذا كانت النافذة مغلقة ولا توجد رسوم متحركة جارية، لا تعرض شيئًا
  if (!isOpen && !isAnimating) return null;

  return (
    <div className="fixed inset-0 z-50 pointer-events-none" ref={overlayRef}>
      {/* النافذة المنبثقة */}
      <animated.div
        ref={contentRef}
        className={`custom-bottom-sheet floating-sheet ${isFirstRender ? 'first-render-animation' : ''}`}
        style={{
          ...slideAnimation,
          width: '94%',
          maxWidth: '500px',
          margin: '0 auto',
          left: '0',
          right: '0',
          bottom: '20px',
          position: 'fixed',
          borderRadius: '20px 20px 35px 35px',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.15)',
          zIndex: 401,
          overflow: 'hidden',
          pointerEvents: 'auto'
        }}
      >
        {/* مقبض السحب */}
        <div className="drag-handle">
          <div className="drag-indicator" style={{ 
            backgroundColor: '#e2e8f0' 
          }}></div>
        </div>
        
        {/* المحتوى */}
        <div 
          className="custom-bottom-sheet-content" 
          style={{ 
            maxHeight: 'calc(70vh - 24px)',
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
            padding: '0 16px 16px',
            overscrollBehavior: 'contain',
            borderRadius: '0 0 35px 35px'
          }}
          dir="rtl"
        >
          {children}
        </div>
      </animated.div>
    </div>
  );
};

export { CustomBottomSheet }; 