import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X, BookOpen, Coffee, Heart, Bell, BellOff, ArrowLeft, Clock } from 'lucide-react';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [remindersEnabled, setRemindersEnabled] = useState(false);
  const [isTasbihListOpen, setIsTasbihListOpen] = useState(false);
  const [isMuezzinsPage, setIsMuezzinsPage] = useState(false);
  const location = useLocation();

  // التحقق من حالة التذكيرات عند تحميل المكون
  useEffect(() => {
    const savedRemindersEnabled = localStorage.getItem('remindersEnabled');
    if (savedRemindersEnabled === 'true') {
      setRemindersEnabled(true);
    }
  }, []);

  // مراقبة حالة قائمة التسبيح وصفحة المؤذنين
  useEffect(() => {
    const checkTasbihList = () => {
      if (typeof window !== 'undefined') {
        const isOpen = window.isTasbihListOpen || false;
        setIsTasbihListOpen(isOpen);
        // إغلاق القائمة الرئيسية إذا كانت قائمة التسبيح مفتوحة
        if (isOpen) {
          setMenuOpen(false);
        }
      }
    };

    const checkMuezzinsPage = () => {
      if (typeof window !== 'undefined') {
        const isActive = window.isMuezzinsPage || false;
        setIsMuezzinsPage(isActive);
        // إغلاق القائمة الرئيسية إذا كنا في صفحة المؤذنين
        if (isActive) {
          setMenuOpen(false);
        }
      }
    };

    checkTasbihList();
    checkMuezzinsPage();
    
    window.addEventListener('tasbihListChanged', checkTasbihList);
    
    // إضافة مستمع حدث لمراقبة تغييرات حالة صفحة المؤذنين
    const handleMuezzinsPageChange = () => {
      checkMuezzinsPage();
    };
    
    // مراقبة تغييرات الموقع لتحديث حالة صفحة المؤذنين
    const handleLocationChange = () => {
      if (location.pathname === '/muezzins') {
        window.isMuezzinsPage = true;
      } else {
        window.isMuezzinsPage = false;
      }
      checkMuezzinsPage();
    };
    
    // تنفيذ فحص أولي عند تحميل المكون
    handleLocationChange();
    
    // إضافة مستمعي الأحداث
    window.addEventListener('muezzinsPageChanged', handleMuezzinsPageChange);
    
    return () => {
      window.removeEventListener('tasbihListChanged', checkTasbihList);
      window.removeEventListener('muezzinsPageChanged', handleMuezzinsPageChange);
    };
  }, [location.pathname]); // إضافة location.pathname كتبعية للتأثير

  // طلب إذن الإشعارات
  const requestNotificationPermission = async () => {
    if (!('Notification' in window)) {
      toast.info('متصفحك لا يدعم الإشعارات');
      return false;
    }
    
    if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
      const permission = await Notification.requestPermission();
      
      if (permission === 'granted') {
        toast.success('تم تفعيل التذكيرات بنجاح');
        
        // التحقق من دعم service worker للتنبيهات في PWA
        if ('serviceWorker' in navigator) {
          try {
            const registration = await navigator.serviceWorker.ready;
            if ('pushManager' in registration) {
              // محاولة الاشتراك في خدمة Push
              try {
                await registration.pushManager.subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: urlBase64ToUint8Array(
                    'BEl62iUYgUivxIkv69yViEuiBIa-Ib9-SkvMeAtA3LFgDzkrxZJjSgSnfckjBJuBkr3qBUYIHBQFLXYp5Nksh8U'
                  )
                });
                console.log('تم الاشتراك في خدمة Push بنجاح');
              } catch (err) {
                console.error('فشل الاشتراك في خدمة Push:', err);
              }
            }
          } catch (err) {
            console.error('خطأ في الوصول إلى service worker:', err);
          }
        }
        return true;
      } else {
        toast.info('لن تتلقى تذكيرات بالأذكار');
        return false;
      }
    }
    
    return Notification.permission === 'granted';
  };

  // تبديل حالة التذكيرات
  const toggleReminders = async () => {
    const newState = !remindersEnabled;
    
    if (newState) {
      // طلب إذن الإشعارات إذا تم تفعيل التذكيرات
      const permissionGranted = await requestNotificationPermission();
      if (!permissionGranted) {
        return; // لا تقم بتفعيل التذكيرات إذا لم يتم منح الإذن
      }
      
      // جدولة التذكيرات حسب الصفحة الحالية
      if (isAdhkarPage) {
        scheduleAdhkarReminders();
        toast.success('تم تفعيل تنبيهات الأذكار');
      } else if (isPrayerTimesPage) {
        schedulePrayerReminders();
        toast.success('تم تفعيل تنبيهات أوقات الصلاة');
      }
    }
    
    setRemindersEnabled(newState);
    localStorage.setItem('remindersEnabled', newState.toString());
    
    if (!newState) {
      if (isAdhkarPage) {
        toast.info('تم إلغاء تفعيل تنبيهات الأذكار');
      } else if (isPrayerTimesPage) {
        toast.info('تم إلغاء تفعيل تنبيهات أوقات الصلاة');
      }
      
      // إلغاء التذكيرات المجدولة
      if (window.reminderTimeouts) {
        window.reminderTimeouts.forEach(timeout => clearTimeout(timeout));
        window.reminderTimeouts = [];
      }
    }
  };

  // جدولة تذكيرات الأذكار
  const scheduleAdhkarReminders = () => {
    // إلغاء أي تذكيرات سابقة
    if (window.reminderTimeouts) {
      window.reminderTimeouts.forEach(timeout => clearTimeout(timeout));
    }
    window.reminderTimeouts = [];
    
    // تحديد أوقات التذكير
    const now = new Date();
    const morningTime = new Date(now);
    morningTime.setHours(5, 0, 0, 0); // 5:00 صباحًا
    
    const eveningTime = new Date(now);
    eveningTime.setHours(17, 0, 0, 0); // 5:00 مساءً
    
    // حساب الوقت المتبقي للتذكير التالي
    let morningDelay = morningTime - now;
    if (morningDelay < 0) {
      morningTime.setDate(morningTime.getDate() + 1);
      morningDelay = morningTime - now;
    }
    
    let eveningDelay = eveningTime - now;
    if (eveningDelay < 0) {
      eveningTime.setDate(eveningTime.getDate() + 1);
      eveningDelay = eveningTime - now;
    }
    
    // جدولة التذكيرات
    const scheduleMorningReminder = () => {
      const timeout = setTimeout(() => {
        sendNotification('أذكار الصباح', 'حان وقت أذكار الصباح، لا تنسَ ذكر الله');
        // جدولة التذكير التالي
        scheduleMorningReminder();
      }, morningDelay);
      
      window.reminderTimeouts.push(timeout);
    };
    
    const scheduleEveningReminder = () => {
      const timeout = setTimeout(() => {
        sendNotification('أذكار المساء', 'حان وقت أذكار المساء، لا تنسَ ذكر الله');
        // جدولة التذكير التالي
        scheduleEveningReminder();
      }, eveningDelay);
      
      window.reminderTimeouts.push(timeout);
    };
    
    // بدء جدولة التذكيرات
    scheduleMorningReminder();
    scheduleEveningReminder();
    
    // إرسال إشعار تأكيد
    setTimeout(() => {
      sendNotification('تم تفعيل تنبيهات الأذكار', 'سيتم تذكيرك بأذكار الصباح والمساء في الأوقات المحددة');
    }, 1000);
  };

  // جدولة تذكيرات أوقات الصلاة
  const schedulePrayerReminders = () => {
    // إلغاء أي تذكيرات سابقة
    if (window.reminderTimeouts) {
      window.reminderTimeouts.forEach(timeout => clearTimeout(timeout));
    }
    window.reminderTimeouts = [];
    
    // إرسال إشعار تأكيد
    setTimeout(() => {
      sendNotification('تم تفعيل تنبيهات أوقات الصلاة', 'سيتم تذكيرك بأوقات الصلاة');
    }, 1000);
    
    // ملاحظة: يتم التعامل مع تنبيهات أوقات الصلاة في مكون PrayerTimes
  };

  // إرسال إشعار
  const sendNotification = (title, body) => {
    // التحقق من دعم الإشعارات
    if (!('Notification' in window)) {
      console.log('المتصفح لا يدعم الإشعارات');
      return;
    }
    
    // التحقق من الإذن
    if (Notification.permission !== 'granted') {
      console.log('لم يتم منح إذن الإشعارات');
      return;
    }
    
    // محاولة استخدام service worker أولاً (للـ PWA)
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.ready.then(registration => {
        registration.showNotification(title, {
          body: body,
          icon: '/icon-192x192.png', // تأكد من وجود هذا الملف في المشروع
          badge: '/badge-72x72.png', // تأكد من وجود هذا الملف في المشروع
          vibrate: [200, 100, 200],
          tag: 'adhkar-reminder',
          renotify: true,
          actions: [
            {
              action: 'open',
              title: 'فتح التطبيق'
            }
          ]
        }).catch(err => {
          console.error('خطأ في إرسال الإشعار عبر service worker:', err);
          // استخدام الطريقة التقليدية كخطة بديلة
          fallbackNotification(title, body);
        });
      }).catch(err => {
        console.error('خطأ في الوصول إلى service worker:', err);
        // استخدام الطريقة التقليدية كخطة بديلة
        fallbackNotification(title, body);
      });
    } else {
      // استخدام الطريقة التقليدية
      fallbackNotification(title, body);
    }
  };

  // إشعار بديل باستخدام واجهة Notification التقليدية
  const fallbackNotification = (title, body) => {
    try {
      const notification = new Notification(title, {
        body: body,
        icon: '/icon-192x192.png'
      });
      
      notification.onclick = function() {
        window.focus();
        this.close();
      };
    } catch (err) {
      console.error('خطأ في إرسال الإشعار التقليدي:', err);
    }
  };

  // تحويل Base64 URL إلى Uint8Array (مطلوب لـ VAPID keys)
  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    
    return outputArray;
  };

  // التحقق مما إذا كانت الصفحة الحالية هي صفحة الأذكار
  const isAdhkarPage = location.pathname === '/adhkar';
  // التحقق مما إذا كانت الصفحة الحالية هي صفحة أوقات الصلاة
  const isPrayerTimesPage = location.pathname === '/prayer-times';
  // التحقق مما إذا كانت الصفحة الحالية هي صفحة المؤذنين
  const isMuezzinsPageActive = location.pathname === '/muezzins';
  // التحقق مما إذا كان يجب عرض زر التنبيهات
  const shouldShowNotificationButton = isAdhkarPage || isPrayerTimesPage || isMuezzinsPageActive;

  // مراقبة تغيير الصفحة لتحديث حالة التنبيهات
  useEffect(() => {
    // تحديث حالة التنبيهات عند تغيير الصفحة
    const savedRemindersEnabled = localStorage.getItem('remindersEnabled');
    if (savedRemindersEnabled === 'true') {
      setRemindersEnabled(true);
    } else {
      setRemindersEnabled(false);
    }
  }, [location.pathname]);

  // التعامل مع الضغط على زر القائمة
  const handleMenuClick = () => {
    if (location.pathname === '/tasbih' && isTasbihListOpen) {
      // إغلاق قائمة التسبيح
      if (typeof window !== 'undefined') {
        window.isTasbihListOpen = false;
        window.dispatchEvent(new Event('tasbihListChanged'));
        // إرسال حدث مخصص لإغلاق قائمة التسبيح
        window.dispatchEvent(new CustomEvent('closeTasbihList'));
      }
    } else if (location.pathname === '/muezzins') {
      // العودة من صفحة المؤذنين
      window.history.back();
    } else {
      // فتح/إغلاق القائمة الرئيسية
      setMenuOpen(!menuOpen);
    }
  };

  // إضافة دالة للحصول على عنوان الصفحة
  const getPageTitle = () => {
    switch (location.pathname) {
      case '/':
        return 'القُرآنِ الكَرِيمِ';
      case '/adhkar':
        return 'أذكار الصباح والمساء';
      case '/tasbih':
        return 'التسبيح';
      case '/prayer-times':
        return 'أوقات الصلاة';
      case '/muezzins':
        return 'المؤذنون';
      default:
        return 'القُرآنِ الكَرِيمِ';
    }
  };

  return (
    <>
      {/* Navbar */}
      <header className="fixed top-0 left-0 right-0 z-50 shadow-lg bg-[#528e88]">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            {/* زر القائمة/الرجوع */}
            <button
              type="button"
              onClick={handleMenuClick}
              className="absolute left-4 p-2 rounded-md text-white transition-colors hover:bg-[#3e6b66]"
              aria-label={
                (location.pathname === '/tasbih' && isTasbihListOpen) || 
                location.pathname === '/muezzins'
                  ? 'رجوع' 
                  : menuOpen 
                    ? 'إغلاق القائمة' 
                    : 'فتح القائمة'
              }
            >
              {(location.pathname === '/tasbih' && isTasbihListOpen) || 
               location.pathname === '/muezzins' ? (
                <ArrowLeft size={24} className="transform transition-transform" />
              ) : menuOpen ? (
                <X size={24} />
              ) : (
                <Menu size={24} />
              )}
            </button>

            {/* شعار التطبيق */}
            <div className="flex-1 flex items-center justify-center">
              <motion.span 
                key={location.pathname}
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 20, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="text-xl font-bold text-white"
              >
                {getPageTitle()}
              </motion.span>
            </div>
            
            {/* زر التنبيهات - يظهر في صفحة الأذكار وصفحة أوقات الصلاة */}
            {shouldShowNotificationButton && (
              <button
                onClick={toggleReminders}
                className="absolute right-4 p-2 rounded-full hover:bg-[#3e6b66] transition-colors"
                aria-label={remindersEnabled ? 'إيقاف التنبيهات' : 'تفعيل التنبيهات'}
                title={isAdhkarPage ? 'تنبيهات الأذكار' : isPrayerTimesPage ? 'تنبيهات أوقات الصلاة' : 'تنبيهات المؤذنين'}
              >
                {remindersEnabled ? (
                  <Bell size={20} className="text-white" />
                ) : (
                  <BellOff size={20} className="text-white" />
                )}
                <span className="sr-only">
                  {isAdhkarPage ? 'تنبيهات الأذكار' : isPrayerTimesPage ? 'تنبيهات أوقات الصلاة' : 'تنبيهات المؤذنين'}
                </span>
              </button>
            )}
          </div>
        </div>
      </header>

      {/* خلفية معتمة عند فتح القائمة */}
      <div 
        className={`fixed inset-0 bg-black transition-opacity duration-300 ${
          menuOpen ? 'opacity-50 visible' : 'opacity-0 invisible'
        }`}
        style={{ zIndex: 40 }}
        onClick={() => setMenuOpen(false)}
      />

      {/* القائمة الجانبية */}
      <div 
        className={`fixed top-16 right-4 bg-white shadow-lg transform transition-all duration-300 ease-in-out z-50 rounded-b-lg overflow-hidden ${
          menuOpen ? 'translate-x-0 opacity-100 visible' : 'translate-x-full opacity-0 invisible'
        }`}
      >
        <nav className="py-2">
          <Link
            to="/"
            className={`flex items-center px-6 py-3 text-gray-600 transition-colors ${
              location.pathname === '/' ? 'bg-emerald-50 text-emerald-700' : ''
            }`}
            onClick={() => setMenuOpen(false)}
          >
            <BookOpen size={20} className="ml-3" />
            <span>القرآن الكريم</span>
          </Link>
          <Link
            to="/adhkar"
            className={`flex items-center px-6 py-3 text-gray-600 transition-colors ${
              location.pathname === '/adhkar' ? 'bg-emerald-50 text-emerald-700' : ''
            }`}
            onClick={() => setMenuOpen(false)}
          >
            <Coffee size={20} className="ml-3" />
            <span>أذكار الصباح والمساء</span>
          </Link>
          <Link
            to="/tasbih"
            className={`flex items-center px-6 py-3 text-gray-600 transition-colors ${
              location.pathname === '/tasbih' ? 'bg-emerald-50 text-emerald-700' : ''
            }`}
            onClick={() => setMenuOpen(false)}
          >
            <Heart size={20} className="ml-3" />
            <span>التسبيح</span>
          </Link>
          <Link
            to="/prayer-times"
            className={`flex items-center px-6 py-3 text-gray-600 transition-colors ${
              location.pathname === '/prayer-times' ? 'bg-emerald-50 text-emerald-700' : ''
            }`}
            onClick={() => setMenuOpen(false)}
          >
            <Clock size={20} className="ml-3" />
            <span>أوقات الصلاة</span>
          </Link>
        </nav>
      </div>
    </>
  );
};

export default Navbar; 