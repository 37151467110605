export const adhkar = [
  {
    id: 1,
    text: "سُبْحَانَ اللَّهِ",
    count: 0,
    target: 100,
    color: "#DC2626", // red-600
    reward: "يكتب له ألف حسنة أو يحط عنه ألف خطيئة"
  },
  {
    id: 2,
    text: "سُبْحَانَ اللَّهِ وَبِحَمْدِهِ",
    count: 0,
    target: 100,
    color: "#059669", // emerald-600
    reward: "حُطَّتْ خَطَايَاهُ وَإِنْ كَانَتْ مِثْلَ زَبَدِ الْبَحْرِ. لَمْ يَأْتِ أَحَدٌ يَوْمَ الْقِيَامَةِ بِأَفْضَلَ مِمَّا جَاءَ بِهِ إِلَّا أَحَدٌ قَالَ مِثْلَ مَا قَالَ أَوْ زَادَ عَلَيْهِ"
  },
  {
    id: 3,
    text: "سُبْحَانَ اللَّهِ وَالْحَمْدُ لِلَّهِ",
    count: 0,
    target: 100,
    color: "#2563EB", // blue-600
    reward: "تَمْلَآَنِ مَا بَيْنَ السَّمَاوَاتِ وَالْأَرْضِ"
  },
  {
    id: 4,
    text: "سُبْحَانَ اللهِ العَظِيمِ وَبِحَمْدِهِ",
    count: 0,
    target: 100,
    color: "#7C3AED", // violet-600
    reward: "غرست له نخلة في الجنة"
  },
  {
    id: 5,
    text: "سُبْحَانَ اللَّهِ وَبِحَمْدِهِ ، سُبْحَانَ اللَّهِ الْعَظِيمِ",
    count: 0,
    target: 100,
    color: "#EA580C", // orange-600
    reward: "ثقيلتان في الميزان حبيبتان إلى الرحمن"
  },
  {
    id: 6,
    text: "لَا إلَه إلّا اللهُ وَحْدَهُ لَا شَرِيكَ لَهُ، لَهُ الْمُلْكُ وَلَهُ الْحَمْدُ وَهُوَ عَلَى كُلُّ شَيْءِ قَدِيرِ",
    count: 0,
    target: 100,
    color: "#0891B2", // cyan-600
    reward: "كانت له عدل عشر رقاب، وكتبت له مئة حسنة، ومحيت عنه مئة سيئة، وكانت له حرزا من الشيطان"
  },
  {
    id: 7,
    text: "الْحَمْدُ للّهِ رَبِّ الْعَالَمِينَ",
    count: 0,
    target: 100,
    color: "#4F46E5", // indigo-600
    reward: "تملأ ميزان العبد بالحسنات"
  },
  {
    id: 8,
    text: "لا حَوْلَ وَلا قُوَّةَ إِلا بِاللَّهِ",
    count: 0,
    target: 100,
    color: "#0D9488", // teal-600
    reward: "كنز من كنوز الجنة"
  },
  {
    id: 9,
    text: "الْلَّهُم صَلِّ وَسَلِم وَبَارِك عَلَى سَيِّدِنَا مُحَمَّد",
    count: 0,
    target: 100,
    color: "#9333EA", // purple-600
    reward: "من صلى على حين يصبح وحين يمسى ادركته شفاعتى يوم القيامة"
  },
  {
    id: 10,
    text: "أستغفر الله",
    count: 0,
    target: 100,
    color: "#DB2777", // pink-600
    reward: "لفعل الرسول صلى الله عليه وسلم"
  },
  {
    id: 11,
    text: "سُبْحَانَ الْلَّهِ، وَالْحَمْدُ لِلَّهِ، وَلَا إِلَهَ إِلَّا الْلَّهُ، وَالْلَّهُ أَكْبَرُ",
    count: 0,
    target: 100,
    color: "#C026D3", // fuchsia-600
    reward: "أنهن أحب الكلام الى الله، ومكفرات للذنوب، وغرس الجنة، وجنة لقائلهن من النار، وأحب الى النبي عليه السلام مما طلعت عليه الشمس، والْبَاقِيَاتُ الْصَّالِحَات"
  },
  {
    id: 12,
    text: "لَا إِلَهَ إِلَّا اللَّهُ",
    count: 0,
    target: 100,
    color: "#65A30D", // lime-600
    reward: "أفضل الذكر لا إله إلاّ الله"
  },
  {
    id: 13,
    text: "الْلَّهُ أَكْبَرُ",
    count: 0,
    target: 100,
    color: "#0369A1", // sky-600
    reward: "من قال الله أكبر كتبت له عشرون حسنة وحطت عنه عشرون سيئة. الله أكبر من كل شيء"
  },
  {
    id: 14,
    text: "سُبْحَانَ اللَّهِ ، وَالْحَمْدُ لِلَّهِ ، وَلا إِلَهَ إِلَّا اللَّهُ ، وَاللَّهُ أَكْبَرُ ، اللَّهُمَّ اغْفِرْ لِي ، اللَّهُمَّ ارْحَمْنِي ، اللَّهُمَّ ارْزُقْنِي",
    count: 0,
    target: 100,
    color: "#E11D48", // rose-600
    reward: "خير الدنيا والآخرة"
  },
  {
    id: 15,
    text: "الْحَمْدُ لِلَّهِ حَمْدًا كَثِيرًا طَيِّبًا مُبَارَكًا فِيهِ",
    count: 0,
    target: 100,
    color: "#EA580C", // orange-600
    reward: "قَالَ النَّبِيُّ صَلَّى اللَّهُ عَلَيْهِ وَسَلَّمَ ‏'‏لَقَدْ رَأَيْتُ اثْنَيْ عَشَرَ مَلَكًا يَبْتَدِرُونَهَا، أَيُّهُمْ يَرْفَعُهَا'"
  },
  {
    id: 16,
    text: "اللَّهُ أَكْبَرُ كَبِيرًا ، وَالْحَمْدُ لِلَّهِ كَثِيرًا ، وَسُبْحَانَ اللَّهِ بُكْرَةً وَأَصِيلاً",
    count: 0,
    target: 100,
    color: "#0284C7", // lightBlue-600
    reward: "قَالَ النَّبِيُّ صَلَّى اللَّهُ عَلَيْهِ وَسَلَّمَ 'عَجِبْتُ لَهَا ، فُتِحَتْ لَهَا أَبْوَابُ السَّمَاءِ'"
  }
]; 