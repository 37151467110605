export const reciters = [
    { id: 1, name: 'server8.mp3quran.net/afs', label: 'مشاري العفاسي' },
    { id: 2, name: 'server11.mp3quran.net/yasser', label: 'ياسر الدوسري' },
    { id: 3, name: 'server12.mp3quran.net/maher', label: 'ماهر المعيقلي ' },
    { id: 4, name: 'server8.mp3quran.net/ayyub', label: 'محمد أيوب' },
    { id: 5, name: 'server10.mp3quran.net/jleel', label: 'خالد الجليل' },
    { id: 6, name: 'server7.mp3quran.net/shur', label: 'سعود الشريم' },
    { id: 7, name: 'server7.mp3quran.net/s_gmd', label: 'سعد الغامدي' },
    { id: 8, name: 'server8.mp3quran.net/ahmad_huth', label: 'احمد الحذيفي' },
    { id: 9, name: 'server9.mp3quran.net/hthfi', label: 'علي الحذيفي' },   
    { id: 10, name: 'server11.mp3quran.net/a_jbr', label: 'علي جابر' },
    { id: 11, name: 'server11.mp3quran.net/sds', label: 'عبدالرحمن السديس' },
    { id: 12, name: 'server6.mp3quran.net/qtm', label: 'ناصر القطامي' },
    { id: 13, name: 'server8.mp3quran.net/frs_a', label: 'فارس عباد' },
    { id: 14, name: 'server10.mp3quran.net/ajm', label: 'أحمد العجمي' },
    { id: 15, name: 'server10.mp3quran.net/minsh', label: 'محمد صديق المنشاوي' },
    { id: 16, name: 'server7.mp3quran.net/basit/Almusshaf-Al-Mojawwad', label: 'عبدالباسط عبدالصمد' },
    { id: 17, name: 'server6.mp3quran.net/kurdi', label: 'رعد محمد الكردي' },
    { id: 18, name: 'server13.mp3quran.net/husr', label: 'محمود خليل الحصري' },
    { id: 19, name: 'server10.mp3quran.net/bader/Rewayat-Hafs-A-n-Assem', label: 'بدر التركي' },
    { id: 20, name: 'server6.mp3quran.net/abkr', label: 'إدريس أبكر' },
    { id: 21, name: 'server14.mp3quran.net/mansor', label: 'منصور السالمي' },
  ];
  