import React, { useState, useEffect, useCallback } from 'react';
import { morningAdhkar, eveningAdhkar } from '../data/dailyAdhkar';
import { motion } from 'framer-motion';
import { Share2, Copy, Check, Clock, Sun, Moon, Award, Plus, RotateCcw } from 'lucide-react';
import { toast } from 'react-toastify';

const DailyAdhkar = () => {
  const [activeTab, setActiveTab] = useState('morning');
  const [adhkarList, setAdhkarList] = useState([]);
  const [copiedId, setCopiedId] = useState(null);
  const [completedAdhkar, setCompletedAdhkar] = useState({});
  const [progress, setProgress] = useState(0);
  const [streakCount, setStreakCount] = useState(0);
  const [lastCompletedDate, setLastCompletedDate] = useState(null);
  const [counters, setCounters] = useState({});

  // حساب التقدم بناءً على الأذكار المكتملة
  const calculateProgress = useCallback(() => {
    if (!adhkarList.length) return 0;
    
    const completedCount = adhkarList.filter(dhikr => 
      completedAdhkar[`${activeTab}-${dhikr.id}`]
    ).length;
    
    return (completedCount / adhkarList.length) * 100;
  }, [adhkarList, completedAdhkar, activeTab]);

  // تحديث التقدم وحفظه في التخزين المحلي
  const updateAndSaveProgress = useCallback(() => {
    const newProgress = calculateProgress();
    setProgress(newProgress);
    
    // حفظ التقدم في التخزين المحلي بناءً على التبويب النشط
    if (activeTab === 'morning') {
      localStorage.setItem('morningProgress', newProgress.toString());
    } else {
      localStorage.setItem('eveningProgress', newProgress.toString());
    }
    
    // التحقق من اكتمال جميع الأذكار
    if (newProgress === 100 && adhkarList.length > 0) {
      const today = new Date().toLocaleDateString();
      
      // إذا كان هذا هو أول اكتمال لليوم
      if (lastCompletedDate !== today) {
        const newStreakCount = streakCount + 1;
        setStreakCount(newStreakCount);
        setLastCompletedDate(today);
        
        localStorage.setItem('streakCount', newStreakCount.toString());
        localStorage.setItem('lastCompletedDate', today);
        
        // عرض تنبيه بالإنجاز
        toast.success(
          <div className="flex items-center gap-2">
            <Award className="text-yellow-500" size={20} />
            <div>
              <div>أحسنت! أكملت جميع أذكار {activeTab === 'morning' ? 'الصباح' : 'المساء'}</div>
              <div className="text-xs mt-1">سلسلة الإنجاز: {newStreakCount} يوم</div>
            </div>
          </div>,
          { autoClose: 5000 }
        );
      }
    }
  }, [calculateProgress, activeTab, adhkarList.length, lastCompletedDate, streakCount]);

  // تعريف دالة checkDailyReset باستخدام useCallback
  const checkDailyReset = useCallback(() => {
    const today = new Date().toLocaleDateString();
    const lastCompleted = localStorage.getItem('lastCompletedDate');
    
    // إذا كان اليوم مختلفًا عن آخر يوم تم فيه إكمال الأذكار، قم بإعادة تعيين تقدم اليوم
    if (lastCompleted && lastCompleted !== today) {
      const newCompletedAdhkar = { ...completedAdhkar };
      const newCounters = { ...counters };
      
      // إعادة تعيين أذكار الصباح والمساء
      [...morningAdhkar, ...eveningAdhkar].forEach(dhikr => {
        delete newCompletedAdhkar[`morning-${dhikr.id}`];
        delete newCompletedAdhkar[`evening-${dhikr.id}`];
        delete newCounters[`morning-${dhikr.id}`];
        delete newCounters[`evening-${dhikr.id}`];
      });
      
      setCompletedAdhkar(newCompletedAdhkar);
      setCounters(newCounters);
      localStorage.setItem('completedAdhkar', JSON.stringify(newCompletedAdhkar));
      localStorage.setItem('adhkarCounters', JSON.stringify(newCounters));
      localStorage.setItem('morningProgress', '0');
      localStorage.setItem('eveningProgress', '0');
    }
  }, [completedAdhkar, counters]);

  // تحميل البيانات المحفوظة عند بدء التطبيق
  useEffect(() => {
    // تحديد التبويب النشط بناءً على الوقت عند دخول الصفحة فقط
    const currentHour = new Date().getHours();
    // تحديد التبويب بناءً على الوقت (بين 5 صباحًا و 5 مساءً = صباح، غير ذلك = مساء)
    const initialTab = (currentHour >= 5 && currentHour < 17) ? 'morning' : 'evening';

    // تحميل البيانات المحفوظة
    const savedCompletedAdhkar = localStorage.getItem('completedAdhkar');
    if (savedCompletedAdhkar) {
      setCompletedAdhkar(JSON.parse(savedCompletedAdhkar));
    }
    
    const savedCounters = localStorage.getItem('adhkarCounters');
    if (savedCounters) {
      setCounters(JSON.parse(savedCounters));
    }
    
    const savedStreakCount = localStorage.getItem('streakCount');
    if (savedStreakCount) {
      setStreakCount(parseInt(savedStreakCount, 10));
    }
    
    const savedLastCompletedDate = localStorage.getItem('lastCompletedDate');
    if (savedLastCompletedDate) {
      setLastCompletedDate(savedLastCompletedDate);
    }
    
    // التحقق من إعادة تعيين الأذكار اليومية
    checkDailyReset();
    
    // تعيين التبويب النشط وتحميل التقدم المناسب
    setActiveTab(initialTab);
    const savedProgress = localStorage.getItem(initialTab === 'morning' ? 'morningProgress' : 'eveningProgress');
    if (savedProgress) {
      setProgress(parseFloat(savedProgress));
    }
    
    // تحديث قائمة الأذكار
    setAdhkarList(initialTab === 'morning' ? morningAdhkar : eveningAdhkar);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // تحديث قائمة الأذكار والتقدم عند تغيير التبويب
  useEffect(() => {
    // تحديث قائمة الأذكار
    setAdhkarList(activeTab === 'morning' ? morningAdhkar : eveningAdhkar);
    
    // تحميل التقدم المحفوظ للتبويب النشط
    const savedProgress = localStorage.getItem(activeTab === 'morning' ? 'morningProgress' : 'eveningProgress');
    if (savedProgress) {
      setProgress(parseFloat(savedProgress));
    } else {
      // إذا لم يكن هناك تقدم محفوظ، قم بحساب التقدم بناءً على الأذكار المكتملة
      setTimeout(() => {
        updateAndSaveProgress();
      }, 0);
    }
  }, [activeTab, updateAndSaveProgress]);

  const handleCopy = (id, text) => {
    navigator.clipboard.writeText(text);
    setCopiedId(id);
    toast.success('تم نسخ الذكر بنجاح');
    
    setTimeout(() => {
      setCopiedId(null);
    }, 2000);
  };

  const handleShare = async (text) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'أذكار من تطبيق القرآن الكريم',
          text: text
        });
        toast.success('تم مشاركة الذكر بنجاح');
      } catch (error) {
        toast.error('حدث خطأ أثناء المشاركة');
      }
    } else {
      handleCopy(null, text);
    }
  };

  const toggleCompletion = (id) => {
    const key = `${activeTab}-${id}`;
    const newCompletedAdhkar = {
      ...completedAdhkar,
      [key]: !completedAdhkar[key]
    };
    
    // تحديث الحالة
    setCompletedAdhkar(newCompletedAdhkar);
    
    // حفظ الحالة مباشرة في التخزين المحلي
    localStorage.setItem('completedAdhkar', JSON.stringify(newCompletedAdhkar));
    
    // حساب وحفظ التقدم الجديد
    const newProgress = calculateNewProgress(newCompletedAdhkar);
    setProgress(newProgress);
    localStorage.setItem(activeTab === 'morning' ? 'morningProgress' : 'eveningProgress', newProgress.toString());
  };
  
  // دالة مساعدة لحساب التقدم الجديد
  const calculateNewProgress = (completedState) => {
    if (!adhkarList.length) return 0;
    
    const completedCount = adhkarList.filter(dhikr => 
      completedState[`${activeTab}-${dhikr.id}`]
    ).length;
    
    return (completedCount / adhkarList.length) * 100;
  };

  const resetProgress = () => {
    // إعادة تعيين تقدم اليوم الحالي فقط
    const newCompletedAdhkar = { ...completedAdhkar };
    adhkarList.forEach(dhikr => {
      delete newCompletedAdhkar[`${activeTab}-${dhikr.id}`];
    });
    
    // تحديث الحالة
    setCompletedAdhkar(newCompletedAdhkar);
    
    // حفظ الحالة مباشرة في التخزين المحلي
    localStorage.setItem('completedAdhkar', JSON.stringify(newCompletedAdhkar));
    
    // إعادة تعيين التقدم
    setProgress(0);
    localStorage.setItem(activeTab === 'morning' ? 'morningProgress' : 'eveningProgress', '0');
    
    toast.info('تم إعادة تعيين التقدم');
  };

  // زيادة عداد التكرار
  const incrementCounter = (id) => {
    const key = `${activeTab}-${id}`;
    const dhikr = adhkarList.find(d => d.id === id);
    const targetCount = dhikr ? dhikr.count : 1;
    const currentCount = counters[key] || 0;
    const newCount = currentCount + 1;
    
    // تحديث العدادات
    const newCounters = {
      ...counters,
      [key]: newCount
    };
    
    setCounters(newCounters);
    
    // حفظ العدادات مباشرة في التخزين المحلي
    localStorage.setItem('adhkarCounters', JSON.stringify(newCounters));
    
    // إذا وصل العداد إلى العدد المطلوب، اعتبر الذكر مكتملاً
    if (newCount >= targetCount && !completedAdhkar[key]) {
      toggleCompletion(id);
    }
  };

  // إعادة تعيين عداد التكرار
  const resetCounter = (id) => {
    const key = `${activeTab}-${id}`;
    const newCounters = {
      ...counters,
      [key]: 0
    };
    
    setCounters(newCounters);
    
    // حفظ العدادات مباشرة في التخزين المحلي
    localStorage.setItem('adhkarCounters', JSON.stringify(newCounters));
  };
  
  // تحديث التبويب النشط
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    
    // تحميل التقدم المحفوظ للتبويب الجديد
    const savedProgress = localStorage.getItem(tab === 'morning' ? 'morningProgress' : 'eveningProgress');
    if (savedProgress) {
      setProgress(parseFloat(savedProgress));
    } else {
      // إذا لم يكن هناك تقدم محفوظ، قم بتعيين التقدم إلى 0
      setProgress(0);
    }

    // تحديث قائمة الأذكار
    setAdhkarList(tab === 'morning' ? morningAdhkar : eveningAdhkar);
  };

  return (
    <div className="container mx-auto px-4 py-8 pt-20 max-w-3xl">
      {/* عرض سلسلة الإنجاز إذا كانت أكبر من 0 */}
      {streakCount > 0 && (
        <motion.div
          className="flex items-center gap-2 mb-4 p-3 bg-yellow-50 text-yellow-800 rounded-lg"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Award className="text-yellow-500" size={20} />
          <span>سلسلة الإنجاز: {streakCount} يوم</span>
        </motion.div>
      )}

      {/* التبويبات */}
      <motion.div 
        className="flex mb-8 bg-white rounded-lg shadow-md overflow-hidden"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.2 }}
      >
        <button
          className={`flex-1 py-4 font-medium text-center flex items-center justify-center gap-2 transition-colors ${
            activeTab === 'morning'
              ? 'text-white bg-emerald-600'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
          onClick={() => handleTabChange('morning')}
        >
          <Sun size={18} />
          <span>أذكار الصباح</span>
        </button>
        <button
          className={`flex-1 py-4 font-medium text-center flex items-center justify-center gap-2 transition-colors ${
            activeTab === 'evening'
              ? 'text-white bg-emerald-600'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
          onClick={() => handleTabChange('evening')}
        >
          <Moon size={18} />
          <span>أذكار المساء</span>
        </button>
      </motion.div>

      {/* شريط التقدم */}
      <motion.div
        className="mb-6 bg-white rounded-lg shadow-md p-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.1 }}
      >
        <div className="flex justify-between items-center mb-2">
          <div className="font-medium">التقدم: {Math.round(progress)}%</div>
          <button
            onClick={resetProgress}
            className="text-xs text-red-500 hover:text-red-700 transition-colors"
          >
            إعادة تعيين
          </button>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <motion.div 
            className="bg-emerald-600 h-2.5 rounded-full"
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.5 }}
          />
        </div>
      </motion.div>

      {/* قائمة الأذكار */}
      <div className="space-y-6">
        {adhkarList.map((dhikr, index) => {
          const isCompleted = completedAdhkar[`${activeTab}-${dhikr.id}`];
          
          return (
            <motion.div
              key={dhikr.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.1 + index * 0.05 }}
              className={`bg-white rounded-lg shadow-md p-5 border-r-4 card-hover ${
                isCompleted ? 'border-emerald-500' : 'border-gray-300'
              }`}
            >
              <div className="flex justify-between items-start mb-3">
                <div className="flex items-center">
                  <div 
                    className={`w-8 h-8 rounded-full flex items-center justify-center mr-2 cursor-pointer ${
                      isCompleted ? 'bg-emerald-100 text-emerald-600' : 'bg-gray-100 text-gray-600'
                    }`}
                    onClick={() => toggleCompletion(dhikr.id)}
                  >
                    {isCompleted ? (
                      <Check size={16} />
                    ) : (
                      <span className="text-sm font-medium">{dhikr.id}</span>
                    )}
                  </div>
                </div>
                <div className="flex space-x-2 rtl:space-x-reverse">
                  {dhikr.count > 1 && (
                    <span className="bg-emerald-100 text-emerald-800 text-xs font-medium px-2.5 py-1 rounded-full ml-1 flex items-center">
                      <span className="ml-0.5">×</span>{dhikr.count}
                    </span>
                  )}
                  <button
                    onClick={() => handleCopy(dhikr.id, dhikr.text)}
                    className="text-gray-500 hover:text-emerald-600 transition-colors"
                    title="نسخ"
                  >
                    {copiedId === dhikr.id ? <Check size={18} /> : <Copy size={18} />}
                  </button>
                  <button
                    onClick={() => handleShare(dhikr.text)}
                    className="text-gray-500 hover:text-emerald-600 transition-colors"
                    title="مشاركة"
                  >
                    <Share2 size={18} />
                  </button>
                </div>
              </div>

              <p className="text-gray-800 text-lg leading-relaxed mb-3 font-arabic">
                {dhikr.text}
                {dhikr.count > 1 && (
                  <span className="block mt-2 text-sm text-emerald-600 font-medium text-left">
                    (تُقرأ {dhikr.count} مرات)
                  </span>
                )}
              </p>

              {dhikr.count > 1 && (
                <div className="mt-3 mb-4 flex items-center justify-between bg-gray-50 p-2 rounded-md">
                  <div className="flex items-center">
                    <span className="text-sm text-gray-600 ml-2">عداد التكرار:</span>
                    <motion.span 
                      className="text-emerald-700 font-medium"
                      key={counters[`${activeTab}-${dhikr.id}`] || 0}
                      initial={{ scale: 1 }}
                      animate={{ 
                        scale: [1, 1.2, 1],
                        color: counters[`${activeTab}-${dhikr.id}`] >= dhikr.count ? '#047857' : '#047857'
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      {counters[`${activeTab}-${dhikr.id}`] || 0} / {dhikr.count}
                    </motion.span>
                    {counters[`${activeTab}-${dhikr.id}`] >= dhikr.count && (
                      <motion.span
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        className="text-emerald-600 mr-2"
                      >
                        <Check size={16} />
                      </motion.span>
                    )}
                  </div>
                  <div className="flex items-center gap-1">
                    <motion.button
                      onClick={() => incrementCounter(dhikr.id)}
                      className="p-1.5 bg-emerald-100 text-emerald-700 rounded-full hover:bg-emerald-200 transition-colors"
                      title="زيادة العداد"
                      whileTap={{ scale: 0.9 }}
                    >
                      <Plus size={16} />
                    </motion.button>
                    <motion.button
                      onClick={() => resetCounter(dhikr.id)}
                      className="p-1.5 bg-gray-100 text-gray-700 rounded-full hover:bg-gray-200 transition-colors"
                      title="إعادة تعيين العداد"
                      whileTap={{ scale: 0.9 }}
                    >
                      <RotateCcw size={16} />
                    </motion.button>
                  </div>
                </div>
              )}

              <div className="mt-4 flex flex-wrap justify-between items-center text-sm">
                <span className="text-gray-600 mb-1">
                  <span className="font-medium text-emerald-700">المصدر: </span>
                  {dhikr.reference}
                </span>
                
                <div 
                  className="flex items-center text-emerald-700 cursor-pointer"
                  onClick={() => toggleCompletion(dhikr.id)}
                >
                  <Clock size={16} className="ml-1" />
                  <span className={isCompleted ? 'line-through' : ''}>
                    {isCompleted ? 'تم الانتهاء' : 'اضغط عند الانتهاء'}
                  </span>
                </div>
              </div>

              {dhikr.benefit && (
                <div className="mt-3 pt-3 border-t border-gray-100">
                  <p className="text-gray-600 text-sm">
                    <span className="font-medium text-emerald-700">الفضل: </span>
                    {dhikr.benefit}
                  </p>
                </div>
              )}
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default DailyAdhkar; 