import React, { useState, useEffect, useRef } from 'react';
import { adhkar } from '../data/adhkar';
import { motion, AnimatePresence } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import '../styles/Tasbih.css';

const Tasbih = () => {
  const [currentDhikr, setCurrentDhikr] = useState(adhkar[0]);
  const [counts, setCounts] = useState(() => {
    const saved = localStorage.getItem('tasbihCounts');
    return saved ? JSON.parse(saved) : adhkar.reduce((acc, dhikr) => ({
      ...acc,
      [dhikr.id]: 0
    }), {});
  });
  const [completionCounts, setCompletionCounts] = useState(() => {
    const saved = localStorage.getItem('completionCounts');
    return saved ? JSON.parse(saved) : adhkar.reduce((acc, dhikr) => ({
      ...acc,
      [dhikr.id]: 0
    }), {});
  });
  const [showReward, setShowReward] = useState(false);
  const [showAdhkarList, setShowAdhkarList] = useState(false);
  const swiperRef = useRef(null);
  const emeraldColor = '#047857'; // لون أخضر زمردي متوافق مع صفحة الأذكار

  useEffect(() => {
    localStorage.setItem('tasbihCounts', JSON.stringify(counts));
    localStorage.setItem('completionCounts', JSON.stringify(completionCounts));
  }, [counts, completionCounts]);

  useEffect(() => {
    // الاستماع لحدث إغلاق القائمة من Navbar
    const handleCloseTasbihList = () => {
      setShowAdhkarList(false);
    };

    window.addEventListener('closeTasbihList', handleCloseTasbihList);
    
    return () => {
      window.removeEventListener('closeTasbihList', handleCloseTasbihList);
    };
  }, []);

  useEffect(() => {
    if (showAdhkarList) {
      document.body.classList.add('no-scroll', 'modal-open');
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      document.body.classList.remove('no-scroll', 'modal-open');
      document.body.style.top = '';
      if (scrollY) {
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      }
    }
    
    return () => {
      document.body.classList.remove('no-scroll', 'modal-open');
      document.body.style.top = '';
    };
  }, [showAdhkarList]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.isTasbihListOpen = showAdhkarList;
      window.dispatchEvent(new Event('tasbihListChanged'));
    }
  }, [showAdhkarList]);

  const handleCount = () => {
    if (counts[currentDhikr.id] >= currentDhikr.target) {
      return;
    }

    const newCount = counts[currentDhikr.id] + 1;
    setCounts(prev => ({
      ...prev,
      [currentDhikr.id]: newCount
    }));

    if (newCount === currentDhikr.target) {
      setShowReward(true);
      setCompletionCounts(prev => ({
        ...prev,
        [currentDhikr.id]: prev[currentDhikr.id] + 1
      }));
      
      setTimeout(() => {
        setShowReward(false);
      }, 3000);
    }
  };

  const resetCount = () => {
    setCounts(prev => ({
      ...prev,
      [currentDhikr.id]: 0
    }));
  };

  const currentIndex = adhkar.findIndex(d => d.id === currentDhikr.id) + 1;

  const shareText = async () => {
    const textToShare = currentDhikr.text;
    
    try {
      if (navigator.share) {
        await navigator.share({
          text: textToShare,
        });
      } else {
        await navigator.clipboard.writeText(textToShare);
        alert('تم نسخ النص');
      }
    } catch (error) {
      console.log('Error sharing:', error);
      try {
        await navigator.clipboard.writeText(textToShare);
        alert('تم نسخ النص');
      } catch (err) {
        console.log('Error copying:', err);
        alert('عذراً، لم نتمكن من مشاركة أو نسخ النص');
      }
    }
  };

  const updateTasbihListState = (isOpen) => {
    setShowAdhkarList(isOpen);
    if (typeof window !== 'undefined') {
      window.isTasbihListOpen = isOpen;
      window.dispatchEvent(new Event('tasbihListChanged'));
    }
  };

  const formatNumber = (number) => {
    if (number >= 1000) {
      const k = number / 1000;
      // إذا كان الرقم يقبل القسمة على 1000 بدون باقي (مثل 1000, 2000)
      if (Number.isInteger(k)) {
        return Math.floor(k) + 'K';
      }
      // إذا كان هناك كسور (مثل 1100, 1200)
      return k.toFixed(1) + 'K';
    }
    return number;
  };

  return (
    <div className={`h-screen flex flex-col relative bg-pattern min-h-screen pb-safe ${showAdhkarList ? 'overflow-hidden' : ''}`}>
      <div className="absolute inset-0 opacity-20 bg-[url('https://www.transparenttextures.com/patterns/arabesque.png')]" />
      <div className="flex-1 pt-20 p-6 flex flex-col items-center overflow-hidden relative z-10">
        <motion.div 
          className="w-full max-w-2xl"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Swiper
            modules={[Navigation]}
            className="w-full h-full"
            spaceBetween={15}
            slidesPerView="auto"
            centeredSlides={true}
            ref={swiperRef}
            onSlideChange={(swiper) => {
              setCurrentDhikr(adhkar[swiper.activeIndex]);
            }}
          >
            {adhkar.map((dhikr, index) => (
              <SwiperSlide key={dhikr.id} className="!w-[88%] sm:!w-[92%]">
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ 
                    opacity: 1, 
                    scale: swiperRef.current?.swiper?.activeIndex === index ? 1.02 : 1 
                  }}
                  transition={{ duration: 0.3 }}
                  className="p-6 rounded-2xl bg-white shadow-lg flex flex-col items-center justify-center min-h-[180px] h-[180px] cursor-pointer"
                  style={{ borderWidth: '2px', borderStyle: 'solid', borderColor: emeraldColor }}
                >
                  <motion.h2 
                    className="text-xl sm:text-2xl font-bold text-center leading-relaxed font-arabic"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2, duration: 0.3 }}
                  >
                    {dhikr.text}
                  </motion.h2>
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>

          <motion.div 
            className="flex items-center justify-between mt-4 px-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.3 }}
          >
            <div className="flex items-center gap-2">
              <button
                onClick={() => updateTasbihListState(true)}
                className="p-1 rounded-full text-emerald-700 transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
              <p className="text-sm font-medium text-emerald-700" dir="rtl">
                {currentIndex} من {adhkar.length}
              </p>
            </div>
            <button
              onClick={shareText}
              className="w-10 h-10 rounded-full flex items-center justify-center text-emerald-700"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
              </svg>
            </button>
          </motion.div>
        </motion.div>

        <motion.div 
          className="flex flex-col items-center mt-12 w-full max-w-md pt-20 pb-20"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <div className="flex items-center justify-between w-full max-w-xs px-4">
            <div className="flex flex-col items-center">
              <p className="text-xs text-gray-500 mb-1">مرات التكرار</p>
              <motion.div 
                className="w-12 h-12 rounded-full border-2 flex items-center justify-center number-circle"
                style={{ borderColor: emeraldColor, color: emeraldColor }}
                animate={{ 
                  scale: [1, 1.1, 1],
                  transition: { duration: 0.3 }
                }}
                key={completionCounts[currentDhikr.id]}
              >
                <span className="text-xl font-bold">
                  {completionCounts[currentDhikr.id]}
                </span>
              </motion.div>
            </div>

            <motion.div 
              className="relative"
              whileHover={{ scale: 1.02 }}
            >
              <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={handleCount}
                className="w-28 h-28 rounded-full flex items-center justify-center text-white shadow-lg relative number-circle bg-emerald-600"
                animate={{
                  scale: counts[currentDhikr.id] === currentDhikr.target ? [1, 1.1, 1] : 1,
                  transition: { duration: 0.3 }
                }}
              >
                <motion.svg
                  className="absolute inset-0"
                  viewBox="0 0 100 100"
                  initial={false}
                >
                  <circle
                    cx="50"
                    cy="50"
                    r="46"
                    fill="none"
                    stroke="rgba(255,255,255,0.2)"
                    strokeWidth="4"
                  />
                  <motion.circle
                    cx="50"
                    cy="50"
                    r="46"
                    fill="none"
                    stroke="white"
                    strokeWidth="4"
                    strokeLinecap="round"
                    transform="rotate(-90 50 50)"
                    initial={{ pathLength: 0 }}
                    animate={{
                      pathLength: Math.min((counts[currentDhikr.id] / currentDhikr.target), 1),
                      transition: { duration: 0.3 }
                    }}
                  />
                </motion.svg>
                <span className={`relative z-10 ${counts[currentDhikr.id] >= 1000 ? 'text-2xl' : 'text-3xl'} font-bold`}>
                  {formatNumber(counts[currentDhikr.id])}
                </span>
              </motion.button>
            </motion.div>

            <motion.div 
              className="flex flex-col items-center"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <p className="text-xs text-gray-500 mb-1">إعادة</p>
              <motion.button
                onClick={resetCount}
                className="w-12 h-12 rounded-full flex items-center justify-center border-2 transition-colors"
                style={{ borderColor: emeraldColor, color: emeraldColor }}
                whileTap={{ rotate: 180 }}
                transition={{ duration: 0.3 }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
              </motion.button>
            </motion.div>
          </div>
          
          <div className="h-16"></div>
        </motion.div>
      </div>

      <AnimatePresence>
        {showAdhkarList && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="fixed inset-0 z-50 flex flex-col bg-white"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="p-4 pt-12 flex justify-between items-center">
           
            </div>
            <div className="flex-1 p-4 overflow-y-auto overscroll-behavior-contain">
              {adhkar.map((dhikr) => (
                <div
                  key={dhikr.id}
                  className="flex items-center justify-between p-4 mb-2 last:mb-0 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors cursor-pointer"
                  onClick={() => {
                    updateTasbihListState(false);
                    if (swiperRef.current) {
                      const index = adhkar.findIndex(d => d.id === dhikr.id);
                      swiperRef.current.swiper.slideTo(index);
                    }
                  }}
                >
                  <div
                    className="w-10 h-10 rounded-full flex items-center justify-center font-medium border-2 number-circle"
                    style={{ borderColor: emeraldColor, color: emeraldColor }}
                  >
                    <span>{completionCounts[dhikr.id]}</span>
                  </div>
                  <div className="flex-1 mx-4">
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium line-clamp-1 flex-1">{dhikr.text}</p>
                      <span className="text-xs text-gray-500 mr-2">
                        {formatNumber(counts[dhikr.id])}/{formatNumber(dhikr.target)}
                      </span>
                    </div>
                    <div className="h-2 bg-gray-200 rounded-full overflow-hidden mt-1">
                      <motion.div
                        className="h-full rounded-full bg-emerald-600"
                        initial={{ width: 0 }}
                        animate={{ 
                          width: `${Math.min((counts[dhikr.id] / dhikr.target) * 100, 100)}%`
                        }}
                        transition={{ 
                          duration: 0.5,
                          ease: "easeInOut"
                        }}
                      />
                    </div>
                  </div>
                  <motion.button
                    className="p-2 rounded-full text-emerald-700"
                    aria-label="إعادة تعيين العداد"
                    initial={{ backgroundColor: "transparent" }}
                    whileHover={{ 
                      scale: 1.1,
                      backgroundColor: "rgba(243, 244, 246, 0.8)"
                    }}
                    transition={{
                      backgroundColor: { duration: 0.2 },
                      scale: { duration: 0.2 }
                    }}
                    whileTap={{ rotate: 180, transition: { duration: 0.3 } }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCounts(prev => ({
                        ...prev,
                        [dhikr.id]: 0
                      }));
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                  </motion.button>
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showReward && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="fixed bottom-4 left-4 right-4 p-4 rounded-lg text-center shadow-xl bg-emerald-50 border border-emerald-200 text-emerald-800"
          >
            <h3 className="text-xl font-bold mb-2">ما شاء الله!</h3>
            <p>
              {currentDhikr.reward}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Tasbih; 