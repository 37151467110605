import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Sunrise, Sun, Sunset, Moon, SunDim, Bell, Calendar, Settings, Music } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import muezzins from '../data/muezzins';

// أسلوب CSS عام لإزالة الحدود السوداء من العناصر التفاعلية
const globalStyles = `
  select, input, button, textarea, a, div, svg, [role="button"] {
    outline: none !important;
    box-shadow: none !important;
    -webkit-tap-highlight-color: transparent !important;
  }
  
  select:focus, input:focus, button:focus, textarea:focus, a:focus, div:focus, svg:focus, [role="button"]:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
  }
  
  input[type="checkbox"]:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #10b981 !important;
  }
  
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
  
  /* إزالة الحدود من نافذة الإعدادات */
  .settings-panel {
    border: none !important;
    outline: none !important;
  }
`;

const PrayerTimes = () => {
  const [location, setLocation] = useState(null);
  const [prayerTimes, setPrayerTimes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [nextPrayer, setNextPrayer] = useState(null);
  const [selectedPrayer, setSelectedPrayer] = useState(null);
  const [timeUntilPrayer, setTimeUntilPrayer] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [hijriDate, setHijriDate] = useState(null);
  const [viewMode, setViewMode] = useState('day'); // day, week, month
  const [previousViewMode, setPreviousViewMode] = useState('day');
  const [monthlyPrayerTimes, setMonthlyPrayerTimes] = useState(null);
  const [adhanEnabled, setAdhanEnabled] = useState(() => {
    return localStorage.getItem('adhanEnabled') === 'true';
  });
  const [reminderMinutes, setReminderMinutes] = useState(() => {
    return parseInt(localStorage.getItem('reminderMinutes') || '15', 10);
  });
  const [showSettings, setShowSettings] = useState(false);
  const [selectedMuezzin, setSelectedMuezzin] = useState(() => {
    return localStorage.getItem('selectedMuezzin') || 'default';
  });

  // التحقق من حالة التنبيهات
  const [notificationsEnabled, setNotificationsEnabled] = useState(() => {
    return localStorage.getItem('remindersEnabled') === 'true';
  });

  const [forceUpdate, setForceUpdate] = useState(0);

  const navigate = useNavigate();

  // مراقبة تغييرات حالة التنبيهات من شريط التنقل
  useEffect(() => {
    const checkNotificationState = () => {
      const isEnabled = localStorage.getItem('remindersEnabled') === 'true';
      setNotificationsEnabled(isEnabled);
    };

    // التحقق من حالة التنبيهات عند تحميل المكون
    checkNotificationState();

    // إضافة مستمع للتغييرات في localStorage
    window.addEventListener('storage', checkNotificationState);
    
    return () => {
      window.removeEventListener('storage', checkNotificationState);
    };
  }, []);

  useEffect(() => {
    // تحديث الوقت الحالي كل ثانية
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchHijriDate = async () => {
      try {
        const response = await fetch('http://api.aladhan.com/v1/gToH');
        const data = await response.json();
        setHijriDate(data.data.hijri);
      } catch (err) {
        console.error('خطأ في جلب التاريخ الهجري:', err);
      }
    };

    fetchHijriDate();
  }, []);

  useEffect(() => {
    // الحصول على الموقع الجغرافي
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const coords = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          setLocation(coords);
          
          try {
            // استخدام API لجلب أوقات الصلاة
            const response = await fetch(
              `https://api.aladhan.com/v1/timings?latitude=${coords.latitude}&longitude=${coords.longitude}&method=4`
            );
            const data = await response.json();
            setPrayerTimes(data.data.timings);
            
            // جلب أوقات الصلاة الشهرية
            await fetchMonthlyPrayerTimes(coords.latitude, coords.longitude);
            
            setLoading(false);
          } catch (err) {
            setError('عذراً، حدث خطأ في جلب أوقات الصلاة');
            setLoading(false);
          }
        },
        () => {
          setError('يرجى السماح بالوصول إلى موقعك لعرض أوقات الصلاة');
          setLoading(false);
        }
      );
    } else {
      setError('متصفحك لا يدعم تحديد الموقع');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (prayerTimes) {
      const prayers = [
        { name: 'Fajr', time: prayerTimes.Fajr },
        { name: 'Dhuhr', time: prayerTimes.Dhuhr },
        { name: 'Asr', time: prayerTimes.Asr },
        { name: 'Maghrib', time: prayerTimes.Maghrib },
        { name: 'Isha', time: prayerTimes.Isha }
      ];

      const now = currentTime;
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentTimeString = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;

      let nextPrayerTime = prayers.find(prayer => prayer.time > currentTimeString);
      if (!nextPrayerTime) {
        nextPrayerTime = prayers[0];
      }

      setNextPrayer(nextPrayerTime);
      if (!selectedPrayer) {
        setSelectedPrayer(nextPrayerTime);
      }

      // حساب الوقت المتبقي للصلاة المختارة
      const prayerToCheck = selectedPrayer || nextPrayerTime;
      const [prayerHour, prayerMinute] = prayerToCheck.time.split(':').map(Number);
      const prayerDate = new Date(now);
      prayerDate.setHours(prayerHour, prayerMinute, 0);
      
      if (prayerDate < now) {
        prayerDate.setDate(prayerDate.getDate() + 1);
      }

      const timeDiff = prayerDate - now;
      const hoursLeft = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutesLeft = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const secondsLeft = Math.floor((timeDiff % (1000 * 60)) / 1000);
      
      setTimeUntilPrayer({
        hours: hoursLeft,
        minutes: minutesLeft,
        seconds: secondsLeft
      });
    }
  }, [currentTime, prayerTimes, selectedPrayer]);

  // تحميل أوقات الصلاة الشهرية
  const fetchMonthlyPrayerTimes = async (latitude, longitude) => {
    try {
      const today = new Date();
      
      // نحصل على التاريخ الهجري الحالي مع تحديد التاريخ الميلادي
      const hijriResponse = await fetch(`http://api.aladhan.com/v1/gToH/${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`);
      const hijriData = await hijriResponse.json();
      setHijriDate(hijriData.data.hijri);
      
      const currentHijriMonth = hijriData.data.hijri.month.ar;
      const currentHijriYear = hijriData.data.hijri.year;
      const currentHijriDay = parseInt(hijriData.data.hijri.day);
      
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      
      // جلب بيانات الشهر الحالي
      const currentMonthResponse = await fetch(
        `https://api.aladhan.com/v1/calendar/${year}/${month}?latitude=${latitude}&longitude=${longitude}&method=4`
      );
      const currentMonthData = await currentMonthResponse.json();
      
      // حساب الشهر القادم
      const nextMonth = month === 12 ? 1 : month + 1;
      const nextYear = month === 12 ? year + 1 : year;
      
      // جلب بيانات الشهر القادم
      const nextMonthResponse = await fetch(
        `https://api.aladhan.com/v1/calendar/${nextYear}/${nextMonth}?latitude=${latitude}&longitude=${longitude}&method=4`
      );
      const nextMonthData = await nextMonthResponse.json();
      
      // دمج البيانات
      const allData = [...currentMonthData.data, ...nextMonthData.data];
      
      // نفلتر البيانات لنحصل على أيام الشهر الهجري الحالي فقط
      const hijriMonthData = allData.filter(day => 
        day.date.hijri.month.ar === currentHijriMonth &&
        parseInt(day.date.hijri.year) === parseInt(currentHijriYear)
      ).sort((a, b) => parseInt(a.date.hijri.day) - parseInt(b.date.hijri.day));

      setMonthlyPrayerTimes(hijriMonthData);
    } catch (err) {
      console.error('خطأ في جلب أوقات الصلاة الشهرية:', err);
    }
  };

  // إعداد التنبيهات - تم نقل هذه الوظيفة إلى شريط التنقل
  const setupNotifications = async () => {
    // تم نقل هذه الوظيفة إلى شريط التنقل
    setShowSettings(!showSettings);
  };

  // إرسال تنبيه
  const sendNotification = (prayerName, time) => {
    if (notificationsEnabled) {
      // إرسال إشعار حتى لو كان الموقع مغلقًا
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.ready.then(registration => {
          registration.showNotification(`حان وقت صلاة ${prayerNames[prayerName]}`, {
            body: `الوقت: ${time}`,
            icon: '/path-to-icon.png',
            vibrate: [200, 100, 200],
            tag: 'prayer-notification',
            renotify: true
          });
        });
      } else {
        // استخدام الإشعارات العادية كخيار احتياطي
        new Notification(`حان وقت صلاة ${prayerNames[prayerName]}`, {
          body: `الوقت: ${time}`,
          icon: '/path-to-icon.png'
        });
      }

      // تشغيل الأذان حتى عندما يكون الموقع مغلقًا
      if (adhanEnabled) {
        // استخدام Web Audio API للتشغيل في الخلفية
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready.then(registration => {
            // إرسال رسالة إلى Service Worker لتشغيل الأذان
            registration.active.postMessage({
              action: 'playAdhan',
              reminderMinutes: reminderMinutes,
              muezzin: selectedMuezzin
            });
          });
        } else {
          // استخدام Audio API كخيار احتياطي
          const selectedMuezzinData = muezzins.find(m => m.id === selectedMuezzin);
          const adhanPath = selectedMuezzinData ? selectedMuezzinData.path : '/assets/adhan.mp3';
          const adhan = new Audio(adhanPath);
          adhan.play();
        }
      }
    }
  };

  // مراقبة الوقت وإرسال التنبيهات
  useEffect(() => {
    if (notificationsEnabled && prayerTimes) {
      const checkPrayerTime = () => {
        const now = new Date();
        const currentTime = now.toLocaleTimeString('en-US', { hour12: false }).slice(0, 5);
        
        Object.entries(prayerTimes).forEach(([prayer, time]) => {
          if (prayerNames[prayer]) {
            // التنبيه قبل الصلاة
            const [prayerHour, prayerMinute] = time.split(':').map(Number);
            const reminderTime = new Date();
            reminderTime.setHours(prayerHour, prayerMinute - reminderMinutes);
            const reminderTimeString = reminderTime.toLocaleTimeString('en-US', { hour12: false }).slice(0, 5);

            if (currentTime === reminderTimeString) {
              new Notification(`تذكير: ${reminderMinutes} دقيقة على صلاة ${prayerNames[prayer]}`);
            }

            // التنبيه عند دخول وقت الصلاة
            if (currentTime === time) {
              sendNotification(prayer, time);
            }
          }
        });
      };

      const interval = setInterval(checkPrayerTime, 30000); // كل 30 ثانية
      return () => clearInterval(interval);
    }
  }, [notificationsEnabled, prayerTimes, reminderMinutes]);

  const handlePrayerSelect = (prayer, time) => {
    setSelectedPrayer({ name: prayer, time });
    setForceUpdate(prev => prev + 1);
  };

  const prayerNames = {
    Fajr: 'الفجر',
    Sunrise: 'الشروق',
    Dhuhr: 'الظهر',
    Asr: 'العصر',
    Maghrib: 'المغرب',
    Isha: 'العشاء'
  };

  const prayerIcons = {
    Fajr: <Sunrise className="w-6 h-6 text-emerald-600" />,
    Sunrise: <Sun className="w-6 h-6 text-emerald-600" />,
    Dhuhr: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6 text-emerald-600">
      <circle cx="12" cy="12" r="4" />
      <path d="M12 2v2" />
      <path d="M12 20v2" />
      <path d="m4.93 4.93 1.41 1.41" />
      <path d="m17.66 17.66 1.41 1.41" />
      <path d="M2 12h2" />
      <path d="M20 12h2" />
      <path d="m6.34 17.66-1.41 1.41" />
      <path d="m19.07 4.93-1.41 1.41" />
      <path d="M10 12h4" />
    </svg>,
    Asr: <SunDim className="w-6 h-6 text-emerald-600" />,
    Maghrib: <Sunset className="w-6 h-6 text-emerald-600" />,
    Isha: <Moon className="w-6 h-6 text-emerald-600" />
  };

  // مراقبة تغييرات المؤذن المختار
  useEffect(() => {
    const checkMuezzinState = () => {
      const savedMuezzin = localStorage.getItem('selectedMuezzin');
      if (savedMuezzin) {
        setSelectedMuezzin(savedMuezzin);
      }
    };

    // التحقق من المؤذن المختار عند تحميل المكون
    checkMuezzinState();

    // إضافة مستمع للتغييرات في localStorage
    window.addEventListener('storage', checkMuezzinState);
    
    return () => {
      window.removeEventListener('storage', checkMuezzinState);
    };
  }, []);

  // حفظ المؤذن المختار في التخزين المحلي عند تغييره
  useEffect(() => {
    localStorage.setItem('selectedMuezzin', selectedMuezzin);
  }, [selectedMuezzin]);

  // حفظ حالة تفعيل الأذان عند تغييرها
  useEffect(() => {
    localStorage.setItem('adhanEnabled', adhanEnabled);
  }, [adhanEnabled]);

  // وظيفة مساعدة لعرض رسائل التأكيد
  const showToast = (message) => {
    // إنشاء عنصر التنبيه
    const toast = document.createElement('div');
    toast.className = 'fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-emerald-600 text-white px-6 py-3 rounded-lg shadow-lg z-50 flex items-center';
    toast.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
      <span>${message}</span>
    `;
    
    // إضافة العنصر إلى الصفحة
    document.body.appendChild(toast);
    
    // إزالة العنصر بعد 3 ثوان
    setTimeout(() => {
      toast.style.opacity = '0';
      toast.style.transition = 'opacity 0.5s ease';
      setTimeout(() => {
        document.body.removeChild(toast);
      }, 500);
    }, 3000);
  };

  const handleAdhanToggle = async (newState) => {
    // التحقق من إذن الإشعارات قبل تفعيل الأذان
    if (newState && !notificationsEnabled) {
      showToast('يجب تفعيل الإشعارات أولاً من شريط التنقل');
      return;
    }

    if (newState) {
      // التحقق من إذن الإشعارات
      if (Notification.permission !== 'granted') {
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
          showToast('يجب السماح بالإشعارات لتفعيل الأذان');
          return;
        }
      }
      
      // التأكد من وجود Service Worker
      if (!('serviceWorker' in navigator)) {
        showToast('متصفحك لا يدعم تشغيل الأذان في الخلفية');
        return;
      }
    }

    setAdhanEnabled(newState);
    localStorage.setItem('adhanEnabled', newState.toString());
    
    if (newState) {
      navigate('/muezzins');
    } else {
      showToast('تم إيقاف الأذان');
    }
  };

  // إضافة وظيفة الاختبار
  const testAdhanSystem = async () => {
    if (!notificationsEnabled) {
      showToast('يجب تفعيل الإشعارات أولاً من شريط التنقل');
      return;
    }

    // اختبار التنبيه
    if (Notification.permission === 'granted') {
      // إرسال تنبيه تجريبي
      new Notification('اختبار التنبيهات', {
        body: 'هذا تنبيه تجريبي للتأكد من عمل النظام',
        icon: '/path-to-icon.png'
      });

      // اختبار الأذان إذا كان مفعلاً
      if (adhanEnabled) {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready.then(registration => {
            registration.active.postMessage({
              action: 'playAdhan',
              muezzin: selectedMuezzin
            });
          });
        }
        showToast('جاري تشغيل الأذان التجريبي');
      }
    } else {
      showToast('يجب السماح بالإشعارات في المتصفح');
    }
  };

  const renderWeeklyView = () => {
    if (!monthlyPrayerTimes || !hijriDate) return null;
    
    const weekDays = [];
    
    // نجد اليوم الحالي في البيانات باستخدام التاريخ الهجري
    const currentDayIndex = monthlyPrayerTimes.findIndex(day => 
      parseInt(day.date.hijri.day) === parseInt(hijriDate.day)
    );

    if (currentDayIndex === -1) {
      console.error('لم يتم العثور على اليوم الحالي:', hijriDate.day);
      return null;
    }

    // نأخذ 7 أيام بدءاً من اليوم الحالي
    weekDays.push(...monthlyPrayerTimes.slice(currentDayIndex, currentDayIndex + 7));

    const prayerOrder = ['Fajr', 'Sunrise', 'Dhuhr', 'Asr', 'Maghrib', 'Isha'];

    return (
      <div className="p-4">
        <div className="overflow-x-auto">
          <table className="w-full min-w-[800px] border-collapse bg-white">
            <thead>
              <tr>
                <th className="p-3 text-right font-medium text-emerald-800 border-b">اليوم</th>
                {prayerOrder.map(prayer => (
                  <th key={prayer} className="p-3 text-center font-medium text-emerald-800 border-b">
                    {prayerNames[prayer]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {weekDays.map((dayData, index) => {
                const hijriDate = dayData.date.hijri;
                
                return (
                  <tr key={index} className="border-b hover:bg-gray-50">
                    <td className="p-3 text-right">
                      <div className="flex flex-col">
                        <span className="text-emerald-800 font-medium">
                          {hijriDate.weekday.ar}
                        </span>
                        <span className="text-gray-600 text-sm">
                          {hijriDate.day} {hijriDate.month.ar}
                        </span>
                      </div>
                    </td>
                    {prayerOrder.map(prayer => (
                      <td key={prayer} className="p-3 text-center">
                        <span className="font-medium text-emerald-700 font-arabic" dir="ltr">
                          {dayData.timings[prayer]?.slice(0, 5)}
                        </span>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderMonthlyView = () => {
    if (!monthlyPrayerTimes) return null;

    const prayerOrder = ['Fajr', 'Sunrise', 'Dhuhr', 'Asr', 'Maghrib', 'Isha'];
    
    // نحصل على الشهر الهجري الحالي
    const currentHijriMonth = monthlyPrayerTimes[0].date.hijri.month.ar;
    
    // نفلتر الأيام لنعرض فقط أيام الشهر الهجري الحالي
    const currentMonthDays = monthlyPrayerTimes.filter(day => 
      day.date.hijri.month.ar === currentHijriMonth
    ).sort((a, b) => parseInt(a.date.hijri.day) - parseInt(b.date.hijri.day));

    return (
      <div className="p-4">
        <div className="overflow-x-auto">
          <table className="w-full min-w-[800px] border-collapse bg-white">
            <thead>
              <tr>
                <th className="p-3 text-right font-medium text-emerald-800 border-b">اليوم</th>
                {prayerOrder.map(prayer => (
                  <th key={prayer} className="p-3 text-center font-medium text-emerald-800 border-b">
                    {prayerNames[prayer]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentMonthDays.map((day, index) => {
                const hijriDate = day.date.hijri;
                
                return (
                  <tr key={index} className="border-b hover:bg-gray-50">
                    <td className="p-3 text-right">
                      <div className="flex flex-col">
                        <span className="text-emerald-800 font-medium">
                          {hijriDate.weekday.ar}
                        </span>
                        <span className="text-gray-600 text-sm">
                          {hijriDate.day} {hijriDate.month.ar}
                        </span>
                      </div>
                    </td>
                    {prayerOrder.map(prayer => (
                      <td key={prayer} className="p-3 text-center">
                        <span className="font-medium text-emerald-700 font-arabic" dir="ltr">
                          {day.timings[prayer]?.slice(0, 5)}
                        </span>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  // تحديث وضع العرض مع حفظ الوضع السابق
  const handleViewModeChange = (mode) => {
    setPreviousViewMode(viewMode);
    setViewMode(mode);
  };

  return (
    <div className="min-h-screen pt-20 p-6 bg-pattern">
      {/* تطبيق الأنماط العامة */}
      <style>{globalStyles}</style>
      
      <div className="absolute inset-0 opacity-20 bg-[url('https://www.transparenttextures.com/patterns/arabesque.png')]" />
      
      {/* أزرار التبديل بين العروض */}
      <div className="relative z-10 max-w-5xl mx-auto mb-4">
        <div className="bg-white/95 backdrop-blur-sm rounded-lg shadow-md p-4">
          <div className="flex justify-between items-center">
            <div className="flex bg-emerald-50/70 rounded-lg p-1.5 shadow-sm relative overflow-hidden w-72 mx-auto">
              <div className="flex-1 relative">
                <button
                  onClick={() => handleViewModeChange('day')}
                  className={`w-full py-2 rounded-md text-center focus:outline-none focus:ring-0 relative z-10 transition-colors duration-200 ${viewMode === 'day' ? 'text-white font-medium' : 'text-emerald-600 hover:text-emerald-700'}`}
                >
                  اليوم
                </button>
              </div>
              <div className="flex-1 relative">
                <button
                  onClick={() => handleViewModeChange('week')}
                  className={`w-full py-2 rounded-md text-center focus:outline-none focus:ring-0 relative z-10 transition-colors duration-200 ${viewMode === 'week' ? 'text-white font-medium' : 'text-emerald-600 hover:text-emerald-700'}`}
                >
                  الأسبوع
                </button>
              </div>
              <div className="flex-1 relative">
                <button
                  onClick={() => handleViewModeChange('month')}
                  className={`w-full py-2 rounded-md text-center focus:outline-none focus:ring-0 relative z-10 transition-colors duration-200 ${viewMode === 'month' ? 'text-white font-medium' : 'text-emerald-600 hover:text-emerald-700'}`}
                >
                  الشهر
                </button>
              </div>
              
              {/* خلفية متحركة للتاب النشط */}
              <motion.div
                className="absolute top-1.5 bottom-1.5 rounded-md bg-emerald-500 shadow-sm"
                initial={false}
                animate={{
                  left: viewMode === 'month' 
                    ? '0.375rem' 
                    : viewMode === 'week' 
                      ? '33.33%' 
                      : '66.66%'
                }}
                transition={{
                  type: "spring",
                  stiffness: 400,
                  damping: 30
                }}
                style={{
                  width: 'calc(33.33% - 0.75rem)',
                }}
              />
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setShowSettings(!showSettings)}
                className="p-2 rounded-full bg-emerald-50 text-emerald-600 hover:bg-emerald-100 transition-colors focus:outline-none focus:ring-0"
                title="الإعدادات"
              >
                <Settings className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* إعدادات التنبيهات */}
      <AnimatePresence>
        {showSettings && (
          <motion.div 
            className="relative z-10 max-w-5xl mx-auto mb-4 bg-white/95 backdrop-blur-sm rounded-b-lg shadow-lg overflow-hidden border border-t-0  settings-panel"
            style={{ marginTop: "-8px" }}
            initial={{ opacity: 0, height: 0, scaleY: 0, transformOrigin: "top" }}
            animate={{ opacity: 1, height: "auto", scaleY: 1 }}
            exit={{ opacity: 0, height: 0, scaleY: 0, transformOrigin: "top" }}
            transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
          >
            <div className="p-5">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-xl font-medium text-emerald-800 flex items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 text-emerald-600">
                    <circle cx="12" cy="12" r="3"></circle>
                    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                  </svg>
                  الإعدادات
                </h3>
                <button 
                  onClick={() => setShowSettings(false)}
                  className="p-1.5 rounded-full hover:bg-emerald-100 text-emerald-600 transition-colors focus:outline-none focus:ring-0"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
              <div className="space-y-4">
                <div className="flex items-center justify-between mb-4 p-3">
                  <span className="text-emerald-700 font-medium cursor-pointer select-none">تفعيل الأذان</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={adhanEnabled}
                      onChange={(e) => handleAdhanToggle(e.target.checked)}
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-emerald-300 dark:peer-focus:ring-emerald-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-emerald-600"></div>
                  </label>
                </div>
                
                <motion.div 
                  className="flex items-center justify-between bg-white p-3"
                >
                  <label htmlFor="reminderMinutes" className="text-emerald-700 font-medium cursor-pointer select-none">
                    التذكير قبل الأذان بـ:
                  </label>
                  <div className="relative">
                    <select
                      id="reminderMinutes"
                      value={reminderMinutes}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        setReminderMinutes(value);
                        localStorage.setItem('reminderMinutes', value.toString());
                      }}
                      className="rounded-md border border-emerald-200 text-emerald-700 bg-emerald-50 px-3 py-1.5 focus:outline-none focus:ring-0 focus:border-emerald-200 appearance-none cursor-pointer transition-all duration-200 hover:bg-emerald-100 rtl:pr-3 rtl:pl-10"
                      style={{ 
                        WebkitAppearance: 'none', 
                        MozAppearance: 'none', 
                        backgroundImage: 'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%2310b981\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3e%3cpolyline points=\'6 9 12 15 18 9\'%3e%3c/polyline%3e%3c/svg%3e")', 
                        backgroundRepeat: 'no-repeat', 
                        backgroundPosition: 'left 0.5rem center', 
                        backgroundSize: '1.2em 1.2em', 
                        paddingRight: '2.5rem',
                        boxShadow: 'none',
                        outline: 'none'
                      }}
                    >
                      <option value="5" className="py-2 text-emerald-700">5 دقائق</option>
                      <option value="10" className="py-2 text-emerald-700">10 دقائق</option>
                      <option value="15" className="py-2 text-emerald-700">15 دقيقة</option>
                      <option value="20" className="py-2 text-emerald-700">20 دقيقة</option>
                      <option value="30" className="py-2 text-emerald-700">30 دقيقة</option>
                    </select>
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-emerald-500 ml-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </motion.div>

                <div className="mt-4 p-3 border-t border-gray-100">
                  <div className="flex items-center justify-between">
                    <span className="text-emerald-700 font-medium">اختبار النظام</span>
                    <button
                      onClick={testAdhanSystem}
                      className="px-4 py-2 bg-emerald-100 text-emerald-700 rounded-md hover:bg-emerald-200 transition-colors"
                    >
                      تجربة التنبيهات والأذان
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* محتوى التابات مع تأثيرات انتقالية */}
      <AnimatePresence mode="wait">
        {viewMode === 'day' && (
          <motion.div 
            key="day-view"
            className="relative z-10 max-w-3xl mx-auto bg-white/95 backdrop-blur-sm rounded-2xl shadow-xl overflow-hidden"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            {loading ? (
              <div className="text-center p-8">
                <div className="animate-spin rounded-full h-12 w-12 border-4 border-emerald-500 border-t-transparent mx-auto mb-4"></div>
                <p className="text-gray-600">جاري تحميل أوقات الصلاة...</p>
              </div>
            ) : error ? (
              <div className="text-center text-red-600 p-6">
                <p className="text-xl mb-2">⚠️</p>
                <p>{error}</p>
              </div>
            ) : (
              <>
                {/* التاريخ الهجري */}
                {hijriDate && (
                  <motion.div 
                    className="bg-emerald-50/50 p-6 text-center border-b border-emerald-100"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1 }}
                  >
                    <div className="space-y-1">
                      <h1 className="text-3xl font-bold text-emerald-800">
                        {hijriDate.day} {hijriDate.month.ar} {hijriDate.year}هـ
                      </h1>
                      <p className="text-lg text-emerald-600">
                        {hijriDate.weekday.ar}
                      </p>
                    </div>
                  </motion.div>
                )}

                {/* الصلاة المختارة والعد التنازلي */}
                {selectedPrayer && (
                  <motion.div 
                    className="p-6 border-b border-emerald-100"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    <div className="flex flex-col items-center">
                      <div className="inline-flex items-center justify-center gap-6 mb-8">
                        <div className="flex items-center gap-2">
                          <span className="text-2xl" role="img">
                            {React.cloneElement(prayerIcons[selectedPrayer.name], {
                              className: `w-6 h-6 ${selectedPrayer.name === 'Sunrise' ? 'text-emerald-600' : 'text-emerald-600'}`
                            })}
                          </span>
                          <h2 className="text-xl font-medium text-emerald-800">
                            {prayerNames[selectedPrayer.name]}
                          </h2>
                        </div>
                        <div className="w-px h-8 bg-emerald-200"></div>
                        <p className="text-2xl font-bold text-emerald-600 font-arabic tracking-wider">
                          {selectedPrayer.time}
                        </p>
                      </div>

                      <div className="flex gap-4 justify-center">
                        <motion.div 
                          className="text-center bg-emerald-50 px-4 py-2 rounded-lg"
                          initial={{ scale: 1, y: 10 }}
                          animate={{ scale: 1, y: 0 }}
                          transition={{ delay: 0.3 }}
                        >
                          <p className="text-2xl font-bold text-emerald-800">{timeUntilPrayer.hours}</p>
                          <p className="text-xs text-emerald-600">ساعة</p>
                        </motion.div>
                        <div className="text-2xl text-emerald-300 self-center">:</div>
                        <motion.div 
                          className="text-center bg-emerald-50 px-4 py-2 rounded-lg"
                          initial={{ scale: 1, y: 10 }}
                          animate={{ scale: 1, y: 0 }}
                          transition={{ delay: 0.4 }}
                        >
                          <p className="text-2xl font-bold text-emerald-800">{timeUntilPrayer.minutes}</p>
                          <p className="text-xs text-emerald-600">دقيقة</p>
                        </motion.div>
                        <div className="text-2xl text-emerald-300 self-center">:</div>
                        <motion.div 
                          className="text-center bg-emerald-50 px-4 py-2 rounded-lg"
                          initial={{ scale: 1, y: 10 }}
                          animate={{ scale: 1, y: 0 }}
                          transition={{ delay: 0.5 }}
                        >
                          <p className="text-2xl font-bold text-emerald-800">{timeUntilPrayer.seconds}</p>
                          <p className="text-xs text-emerald-600">ثانية</p>
                        </motion.div>
                      </div>
                    </div>
                  </motion.div>
                )}

                {/* جدول أوقات الصلاة */}
                <motion.div 
                  className="p-6"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  <div className="grid gap-3">
                    {Object.entries(prayerTimes || {}).map(([prayer, time]) => {
                      if (prayerNames[prayer]) {
                        const isNext = nextPrayer?.name === prayer;
                        const isSelected = selectedPrayer?.name === prayer;
                        return (
                          <motion.div
                            key={`${prayer}-${forceUpdate}`}
                            className={`flex items-center justify-between p-4 rounded-lg select-none ${
                              isSelected 
                                ? 'bg-emerald-50 ring-2 ring-emerald-500 border border-emerald-500' 
                                : isNext
                                ? 'bg-amber-50 ring-2 ring-amber-200 border border-amber-500'
                                : 'bg-white hover:bg-gray-50 border border-transparent'
                            }`}
                            initial={false}

                            transition={{ duration: 0.2 }}
                            onClick={() => handlePrayerSelect(prayer, time)}
                            role="button"
                            tabIndex={0}
                            style={{
                              WebkitTapHighlightColor: 'transparent',
                              userSelect: 'none'
                            }}
                          >
                            <div className="flex items-center gap-3">
                              {React.cloneElement(prayerIcons[prayer], {
                                className: `w-6 h-6 ${isNext && !isSelected ? 'text-amber-700' : 'text-emerald-800'}`
                              })}
                              <span className={`text-lg font-medium ${isNext && !isSelected ? 'text-amber-700' : 'text-emerald-800'}`}>
                                {prayerNames[prayer]}
                              </span>
                            </div>
                            <div className="flex items-center gap-2">
                              <span className={`text-lg font-bold ${isNext ? 'text-amber-600' : 'text-emerald-600'} font-arabic`}>
                                {time}
                              </span>
                              {isNext && (
                                <span className="text-xs bg-amber-100 text-amber-600 px-2 py-1 rounded-full">
                                  الصلاة القادمة
                                </span>
                              )}
                            </div>
                          </motion.div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </motion.div>
              </>
            )}
          </motion.div>
        )}
        {viewMode === 'week' && (
          <motion.div 
            key="week-view"
            className="relative z-10 max-w-5xl mx-auto bg-white/95 backdrop-blur-sm rounded-2xl shadow-xl overflow-hidden"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            {loading ? (
              <div className="text-center p-8">
                <div className="animate-spin rounded-full h-12 w-12 border-4 border-emerald-500 border-t-transparent mx-auto mb-4"></div>
                <p className="text-gray-600">جاري تحميل أوقات الصلاة...</p>
              </div>
            ) : error ? (
              <div className="text-center text-red-600 p-6">
                <p className="text-xl mb-2">⚠️</p>
                <p>{error}</p>
              </div>
            ) : (
              renderWeeklyView()
            )}
          </motion.div>
        )}
        {viewMode === 'month' && (
          <motion.div 
            key="month-view"
            className="relative z-10 max-w-5xl mx-auto bg-white/95 backdrop-blur-sm rounded-2xl shadow-xl overflow-hidden"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            {loading ? (
              <div className="text-center p-8">
                <div className="animate-spin rounded-full h-12 w-12 border-4 border-emerald-500 border-t-transparent mx-auto mb-4"></div>
                <p className="text-gray-600">جاري تحميل أوقات الصلاة...</p>
              </div>
            ) : error ? (
              <div className="text-center text-red-600 p-6">
                <p className="text-xl mb-2">⚠️</p>
                <p>{error}</p>
              </div>
            ) : (
              renderMonthlyView()
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PrayerTimes; 