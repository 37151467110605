import React from 'react';
import ReactDOM from 'react-dom/client'; // تأكد من استخدام 'react-dom/client'
import App from './App';
import './styles/index.css';

// منع تحديد النص ونسخها
document.addEventListener('selectstart', (e) => {
  if (window.getSelection) {
    window.getSelection().removeAllRanges();
  }
  return false;
});

// إضافة CSS للتأكيد
document.body.style.userSelect = 'none';
document.body.style.webkitUserSelect = 'none';
document.body.style.mozUserSelect = 'none';
document.body.style.msUserSelect = 'none';
// document.addEventListener('contextmenu', (e) => e.preventDefault());

const root = ReactDOM.createRoot(document.getElementById('root')); // استخدم createRoot
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// تسجيل Service Worker مع تحسينات إضافية
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then((registration) => {
        // console.log('Service Worker مسجل بنجاح:', registration);
        
        // التحقق من وجود تحديثات للـ Service Worker
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          newWorker.addEventListener('statechange', () => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              // يوجد تحديث جديد، يمكن إظهار رسالة للمستخدم
              console.log('يوجد تحديث جديد متاح!');
            }
          });
        });
      })
      .catch((error) => {
        console.error('فشل تسجيل Service Worker:', error);
      });
      
    // التعامل مع الاتصال بالإنترنت
    window.addEventListener('online', () => {
      console.log('متصل بالإنترنت');
    });
    
    window.addEventListener('offline', () => {
      console.log('غير متصل بالإنترنت');
    });
  });
}

// طلب إذن الإشعارات لتحسين تجربة PWA
if ('Notification' in window && 'serviceWorker' in navigator) {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('تم منح إذن الإشعارات');
    }
  });
}

// منع إيقاف الصوت عند قفل الشاشة أو تصغير التطبيق
if ('wakeLock' in navigator) {
  let wakeLock = null;
  
  const requestWakeLock = async () => {
    try {
      wakeLock = await navigator.wakeLock.request('screen');
      console.log('تم تفعيل منع قفل الشاشة');
      
      wakeLock.addEventListener('release', () => {
        console.log('تم إلغاء منع قفل الشاشة');
      });
    } catch (err) {
      console.error('فشل في تفعيل منع قفل الشاشة:', err);
    }
  };
  
  // طلب منع قفل الشاشة عند تشغيل الصوت
  document.addEventListener('play', () => {
    requestWakeLock();
  }, true);
  
  // إلغاء منع قفل الشاشة عند إيقاف الصوت
  document.addEventListener('pause', () => {
    if (wakeLock) {
      wakeLock.release();
      wakeLock = null;
    }
  }, true);
  
  // إعادة طلب منع قفل الشاشة عند استعادة التركيز
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible' && wakeLock === null) {
      // تحقق مما إذا كان الصوت قيد التشغيل
      const audioElements = document.querySelectorAll('audio');
      for (const audio of audioElements) {
        if (!audio.paused) {
          requestWakeLock();
          break;
        }
      }
    }
  });
}
