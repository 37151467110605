import React, { useState } from 'react';
import { 
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Routes,
  Route,
  BrowserRouter as Router
} from 'react-router-dom';
import QuranAudioPlayer from './QuranAudioPlayer';
import Navbar from './components/Navbar';
import Tasbih from './components/Tasbih';
import DailyAdhkar from './components/DailyAdhkar';
import PrayerTimes from './pages/PrayerTimes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Muezzins from './pages/Muezzins';

// تخصيص أنماط التنبيهات
const toastStyles = {
  style: {
    background: '#ffffff',
    color: '#1f2937',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    borderRadius: '0.5rem',
    fontSize: '1rem',
    fontWeight: '500'
  }
};

// إنشاء سياق للمشغل
export const PlayerContext = React.createContext();

function Layout() {
  // إدارة حالة المشغل
  const [playerState, setPlayerState] = useState({
    isVisible: false,
    currentSurah: null,
    selectedReciter: null,
    isPlaying: false
  });

  return (
    <PlayerContext.Provider value={{ playerState, setPlayerState }}>
      <div className="App min-h-screen relative bg-gray-50">
        <div className="absolute inset-0 opacity-20 bg-[url('https://www.transparenttextures.com/patterns/arabesque.png')]" />
        <Navbar />
        
        {/* Main Content */}
        <main className="relative z-10">
          <Outlet />
        </main>

        {/* مشغل القرآن الثابت */}
        {playerState.isVisible && (
          <div className="fixed bottom-0 left-0 right-0 z-50">
            <QuranAudioPlayer 
              initialSurah={playerState.currentSurah}
              initialReciter={playerState.selectedReciter}
              initialIsPlaying={playerState.isPlaying}
              onStateChange={(newState) => setPlayerState(prev => ({ ...prev, ...newState }))}
            />
          </div>
        )}

        <ToastContainer 
          position="top-center"
          autoClose={3000}
          closeOnClick={true}
          draggable={false}
          draggableDirection="up"
          draggablePercent={60}
          hideProgressBar={false}
          pauseOnHover={true}
          newestOnTop={false}
          theme="light"
          toastStyle={toastStyles.style}
          closeButton={false}
          rtl={true}
          className="light-toast"
        />
      </div>
    </PlayerContext.Provider>
  );
}

function App() {
  // تخصيص دالة التنبيهات
  toast.info = (message) => {
    return toast(message, {
      ...toastStyles,
      icon: '📖',
    });
  };

  // إنشاء موجه React Router مع أعلام المستقبل
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Layout />,
        children: [
          {
            index: true,
            element: <QuranAudioPlayer />
          },
          {
            path: "adhkar",
            element: <DailyAdhkar />
          },
          {
            path: "tasbih",
            element: <Tasbih />
          },
          {
            path: "prayer-times",
            element: <PrayerTimes />
          },
          {
            path: "muezzins",
            element: <Muezzins />
          }
        ]
      }
    ],
    {
      basename: process.env.PUBLIC_URL || '/'
    }
  );

  return (
    <RouterProvider 
      router={router} 
      future={{ 
        v7_startTransition: true,
        v7_relativeSplatPath: true
      }} 
    />
  );
}

export default App;