import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown, FaCheck } from 'react-icons/fa';

const ReciterSelector = ({ reciters, selectedReciter, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const menuRef = useRef(null);
  const selectedItemRef = useRef(null); // مرجع للعنصر المختار
  const [menuHeight, setMenuHeight] = useState(0);

  // إغلاق القائمة عند النقر خارجها
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // تعيين ارتفاع القائمة عند الفتح
  useEffect(() => {
    if (isOpen && menuRef.current) {
      setMenuHeight(menuRef.current.scrollHeight);
    }
  }, [isOpen]);

  // التمرير للعنصر المختار عند فتح القائمة باستخدام scrollTo
  useEffect(() => {
    if (isOpen && selectedItemRef.current && menuRef.current) {
      const offsetTop = selectedItemRef.current.offsetTop;
      menuRef.current.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  }, [isOpen]);

  const handleSelect = (reciter) => {
    onSelect(reciter);
    setIsOpen(false);
  };

  return (
    <div className="relative w-full mx-auto pt-4 pb-0" ref={wrapperRef}>
      {/* الصندوق الرئيسي */}
      <button
        className={`
          w-full h-14 px-5 bg-white border-2 rounded-xl flex items-center justify-between
          transition-all duration-300 hover:border-emerald-600 focus:outline-none
          ${isOpen ? 'border-green-600 shadow-lg' : 'border-gray-200 shadow-sm'}
        `}
        onClick={() => setIsOpen(!isOpen)}
        aria-label="اختر القارئ"
        type="button"
      >
        <span className={`text-lg font-medium truncate ${selectedReciter ? 'text-gray-800' : 'text-gray-400'}`}>
          {selectedReciter?.label || 'اختر القارئ'}
        </span>
        <FaChevronDown
          className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180 text-green-600' : 'text-gray-400'}`}
        />
      </button>

      {/* القائمة المنسدلة */}
      <div
        ref={menuRef}
        className={`
          no-scrollbar absolute top-full left-0 w-full bg-white rounded-xl shadow-2xl overflow-hidden
          transition-all duration-300 origin-top z-50
        `}
        style={{
          // إذا كان عدد القراء >= 4 نحدد ارتفاعًا ثابتًا مع إمكانية التمرير دون ظهور شريط التمرير
          maxHeight: isOpen ? (reciters.length >= 4 ? '200px' : `${menuHeight}px`) : '0px',
          opacity: isOpen ? '1' : '0',
          transform: isOpen ? 'translateY(0)' : 'translateY(-10px)',
          overflowY: reciters.length >= 4 ? 'auto' : 'hidden',
        }}
        role="listbox"
      >
        {reciters.map((reciter, index) => (
          <button
            key={reciter.id}
            ref={selectedReciter?.id === reciter.id ? selectedItemRef : null}
            className={`
              w-full px-6 py-4 text-left text-gray-700 hover:bg-emerald-50 flex items-center justify-between
              transition-colors duration-200 ${selectedReciter?.id === reciter.id ? 'bg-emerald-100 font-semibold' : ''}
              ${index !== reciters.length - 1 ? 'border-b border-gray-300' : ''}
            `}
            onClick={() => handleSelect(reciter)}
            role="option"
            aria-selected={selectedReciter?.id === reciter.id}
            type="button"
          >
            <span>{reciter.label}</span>
            {selectedReciter?.id === reciter.id && (
              <FaCheck className="text-green-600 ml-2" />
            )}
          </button>
        ))}
      </div>

      {/* أنماط لإخفاء شريط التمرير */}
      <style>{`
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`}</style>

    </div>
  );
};

ReciterSelector.propTypes = {
  reciters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedReciter: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
};

export default ReciterSelector;
