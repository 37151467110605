import React, { useState, useRef } from 'react';
import { BsSearch, BsX } from 'react-icons/bs'; // أيقونات البحث والإغلاق

const SurahSearch = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef(null);

  // عند التغيير في حقل البحث
  const handleChange = (event) => {
    const term = event.target.value; // احذف .trim() هنا
    setSearchTerm(term);
    onSearch(term.trim()); // (اختياري) إذا أردت إزالة المسافات الزائدة من الأطراف فقط
  };
  
  // عند النقر على أيقونة "X" لإلغاء البحث
  const handleClear = () => {
    setSearchTerm('');
    onSearch(''); // إلغاء النص المرسل للمكون الأب
  };

  return (
    <div className="w-full mx-auto pt-4 pb-4">
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          placeholder="ابحث عن السورة..."
          value={searchTerm}
          onChange={handleChange}
          className="w-full h-14 px-5 bg-white border-2 rounded-xl text-lg font-medium transition-all duration-300 focus:outline-none border-gray-200 shadow-sm peer focus:border-emerald-600"
        />
        {/* عرض أيقونة "X" إذا كان هناك نص في الحقل */}
        {searchTerm && (
          <button
            className="absolute top-[26px] left-3 transform -translate-y-1/2 text-gray-400 cursor-pointer peer-focus:text-emerald-600"
            onClick={handleClear}
            title="مسح النص"
          >
            <BsX className="text-3xl" />
          </button>
        )}
        {/* عرض أيقونة البحث إذا لم يكن هناك نص */}
        {!searchTerm && (
          <button
            className="absolute top-[26px] left-4 transform -translate-y-1/2 text-gray-400 cursor-pointer peer-focus:text-emerald-600"
            onClick={() => inputRef.current?.focus()} // التركيز على حقل الإدخال عند النقر على أيقونة البحث
            title="بحث"
          >
            <BsSearch className="text-2xl" />
          </button>
        )}
      </div>
    </div>
  );
};

export default SurahSearch;
