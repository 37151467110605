// SurahItem.jsx
import React from 'react';
import { Play, Pause, Loader2 } from 'lucide-react';

const SurahItem = ({ surah, isPlaying, onPlay, isLoading }) => {
  const toArabicNumerals = (num) => {
    const arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    return num.toString().split('').map(digit => arabicNumerals[digit]).join('');
  };
  return (
    <div className="bg-white rounded-lg shadow-md p-4 flex items-center justify-between hover:shadow-lg transition-shadow">
      <div className="flex items-center space-x-4 rtl:space-x-reverse">
      <div className="background-nub">
        <div className="surah-number flex items-center justify-center">
          <span className="text-xl items-center font-semibold">{toArabicNumerals(surah.id)}</span>
        </div>
      </div>
        <h2 className="text-2xl text-emerald-900 font-bold">{surah.name} </h2>
        {/* <span className="text-sm bg-emerald-500 text-white px-2.5 py-0.5 rounded-full">
          {surah.aya} آية
        </span> */}
      </div>
      <button
        onClick={onPlay}
        disabled={isLoading}
        className={`
          control-btn flex items-center justify-center 
          w-12 h-12 rounded-full 
          ${isLoading ? '!bg-emerald-600 cursor-wait' : 'hover:bg-emerald-600'}
          text-white
          transition-colors
          ${(isPlaying || isLoading) ? 'active-btn scale-105 shadow-lg border-2 border-emerald-700' : ''}
        `}
      >
        {isLoading ? (
          <Loader2 className="w-6 h-6 animate-spin" />
        ) : isPlaying ? (
          <Pause className="w-6 h-6" />
        ) : (
          <Play className="w-6 h-6" />
        )}
      </button>
    </div>
  );
};

export default SurahItem;